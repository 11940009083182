import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Message, Panel, toaster } from 'rsuite'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { useModal } from '../../hooks/useModal'
import { CategoryService } from '../../services/category/Category.service'
import { ICreateChildDTO } from '../../services/category/dto'

export const CreateChildPage = () => {
	const { id } = useParams<{ id: string }>()
	const queryClient = useQueryClient()
	const { closeHandler } = useModal()
	const navigate = useNavigate()

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ICreateChildDTO>({ mode: 'onChange' })

	const { mutateAsync, isLoading } = useMutation(
		['createMainCategory'],
		(dto: ICreateChildDTO) => CategoryService.createChild(dto)
	)

	const createHandler = async (data: ICreateChildDTO) => {
		try {
			if (!id) {
				toaster.push(
					<Message type="error" showIcon>
						Произошла ошибка. Повторите попытку позже
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...data,
					parentId: +id,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
						queryClient.invalidateQueries(['fetchBySlug'])
						closeHandler()
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}
	return (
		<Panel header="Создать категорию" style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(createHandler)}
			>
				<Form.Group>
					<Form.ControlLabel>Название категории</Form.ControlLabel>
					<Controller<ICreateChildDTO>
						name="name"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>

				<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
			</Form>
		</Panel>
	)
}
