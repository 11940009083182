import { ReactNode, useEffect, useState } from 'react'
import io, { Socket } from 'socket.io-client'
import { WebSocketContext, WebSocketContextType } from '../context/Ws-context'

export interface ISocketResponse {
	userId: number
}

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [socket, setSocket] = useState<Socket | null>(null)

	useEffect(() => {
		const newSocket = io(`${process.env.REACT_APP_SOCKET_URI}`)
		newSocket.on('connect', () => {
			console.log('Successfully connected to the WebSocket server')
		})
		setSocket(newSocket)
	}, [])

	const contextValue: WebSocketContextType = { socket }

	return (
		<WebSocketContext.Provider value={contextValue}>
			{children}
		</WebSocketContext.Provider>
	)
}
