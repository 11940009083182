// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_headItem__4w0ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_list__ccrRw {
  display: flex;
  flex-wrap: wrap;
}

.product_someItem__pbwdu {
  min-width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/products/%5Bproduct%5D/product.module.scss","webpack://./src/pages/products/[product]/product.module.scss"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;ACDD;;ADGA;EACC,aAAA;EACA,eAAA;ACAD;;ADEA;EACC,cAAA;ACCD","sourcesContent":["@import '../../../styles/vars';\n@import '../../../styles/mixin';\n.headItem {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n.list {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n}\n.someItem {\n\tmin-width: 50%;\n}\n",".headItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.someItem {\n  min-width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headItem": `product_headItem__4w0ul`,
	"list": `product_list__ccrRw`,
	"someItem": `product_someItem__pbwdu`
};
export default ___CSS_LOADER_EXPORT___;
