import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Message, toaster } from 'rsuite'
import { CategoryService } from '../../services/category/Category.service'
import { useDialog } from '../useDialog'
import { useModal } from '../useModal'

export const useDeleteCategory = (invalidateKey: string) => {
	const queryClient = useQueryClient()
	const { closeHandler } = useModal()
	const { openDialog } = useDialog()
	const { mutateAsync: deleteCategory } = useMutation(
		['deleteMainCategory'],
		(id: number) => CategoryService.delete(id)
	)

	const deleteHandler = (id: number) => {
		openDialog({
			onConfirm: async () => {
				try {
					await deleteCategory(id, {
						onSuccess(data, variables, context) {
							toaster.push(
								<Message showIcon type="success">
									{data.message}
								</Message>
							)
							queryClient.invalidateQueries([invalidateKey])
							closeHandler()
						},
					})
				} catch (error) {
					toaster.push(
						<Message showIcon type="error">
							{error}
						</Message>
					)
				}
			},
		})
	}
	return deleteHandler
}
