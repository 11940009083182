import { Message, toaster } from 'rsuite'
import { clearTokensOnStorage } from '../../helpers/auth.helpers'
import { useStoreActions } from '../useStoreActions'

export const useLogOut = () => {
	const { logOutUser } = useStoreActions()

	const logout = async () => {
		logOutUser()
		clearTokensOnStorage()
		toaster.push(
			<Message type="info" showIcon>
				Вы вышли из системы
			</Message>
		)
	}

	return logout
}
