import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Form, Message, Panel, toaster, Uploader } from 'rsuite'
import { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../api/AxiosBase'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { useModal } from '../../hooks/useModal'
import { CategoryService } from '../../services/category/Category.service'
import { ICategoryDTO } from '../../services/category/dto'

export const CreateCategoryPage = () => {
	const [image, setImage] = useState<any>()
	const [icon, setIcon] = useState<any>()
	const [files, setFiles] = useState<FileType[]>([])
	const queryClient = useQueryClient()
	const { closeHandler } = useModal()
	const navigate = useNavigate()

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm<ICategoryDTO>({ mode: 'onChange' })

	const { mutateAsync, isLoading } = useMutation(
		['createMainCategory'],
		(dto: ICategoryDTO) => CategoryService.create(dto)
	)

	const createHandler = async (data: ICategoryDTO) => {
		try {
			if (!image || !icon) {
				toaster.push(
					<Message type="error" showIcon>
						Выберите картинку
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...data,
					image: image.filePath,
					icon: icon.filePath,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								Выберите картинку
							</Message>
						)
						queryClient.invalidateQueries(['fetchParentsCategory'])
						closeHandler()
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}
	return (
		<Panel header="Создать категорию" style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(createHandler)}
			>
				<Form.Group>
					<Form.ControlLabel>Название категории</Form.ControlLabel>
					<Controller<ICategoryDTO>
						name="name"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>Картинка категории</Form.ControlLabel>
					<Uploader
						disabled={files.length > 0}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-image`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setImage(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>Иконка категории</Form.ControlLabel>
					<Uploader
						disabled={!image}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-icon`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setIcon(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>

				<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
			</Form>
		</Panel>
	)
}
