import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Col,
	Form,
	Grid,
	Message,
	Row,
	Text,
	Tooltip,
	useToaster,
	Whisper,
} from 'rsuite'
import Uploader, { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../api/AxiosBase'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { ISelectedCategories } from '../../components/form/product/ProductForm.props'
import { SelectedCategory } from '../../components/form/product/SelectedCategory'
import { Heading } from '../../components/heading/Heading'
import { LoaderComponent } from '../../components/loader/Loader'
import { imgHostPath } from '../../helpers/imgHostPath'
import { useFetchProductBySlug } from '../../hooks/product/useFetchProductBySlug'
import { IUpdateProductDTO } from '../../services/product/dto'
import { ProductService } from '../../services/product/Product.service'

const UpdateProduct = () => {
	const { slug } = useParams<{ slug: string }>()
	const [uploadResponse, setUploadResponse] = useState<any>()
	const navigate = useNavigate()
	const [files, setFiles] = useState<FileType[]>([])
	const toaster = useToaster()
	const [categoryIds, setCategoryIds] = useState<ISelectedCategories>({
		child: undefined,
		main: undefined,
		parent: undefined,
	})

	const { data, isLoading } = useFetchProductBySlug(slug)

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<IUpdateProductDTO>({ mode: 'onChange' })

	const { mutateAsync, isLoading: loadingCreated } = useMutation({
		mutationKey: ['createProduct'],
		mutationFn: (values: IUpdateProductDTO) =>
			ProductService.update(values, data?.id),
	})

	useEffect(() => {
		if (data) {
			setValue('title', data.title)
			setValue('description', data.description)
			setValue('subtitle', data.subtitle)
		}
	}, [data])

	const updateHandle = async (values: IUpdateProductDTO) => {
		try {
			const categories = Object.values(categoryIds).filter(
				(id) => id !== undefined
			)
			const updateData =
				categories.length > 0
					? {
							description: values.description,
							title: values.title,
							subtitle: values.subtitle,
							categoryIds: categories,
					  }
					: uploadResponse
					? {
							description: values.description,
							title: values.title,
							subtitle: values.subtitle,
							poster: uploadResponse.filePath,
					  }
					: {
							description: values.description,
							title: values.title,
							subtitle: values.subtitle,
					  }

			console.log(updateData)
			await mutateAsync(updateData, {
				onSuccess(data, error) {
					toaster.push(
						<Message showIcon type="success">
							{data?.message}
						</Message>
					)
					navigate(-1)
				},
			})
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{error.message}
				</Message>
			)
		}
	}

	return isLoading ? (
		<LoaderComponent />
	) : (
		data && (
			<Grid fluid>
				<Row gutter={20} style={{ zIndex: 0 }}>
					<Col xs={12}>
						<Heading
							style={{ margin: '12px 0px' }}
							type="h5"
							children={`Редактировать ${data.title}`}
						/>
						<Form
							// @ts-ignore
							onSubmit={handleSubmit(updateHandle)}
						>
							<Controller
								name="title"
								control={control}
								rules={{ required: 'Название обязательно' }}
								defaultValue={data.title}
								render={({ field, fieldState }) => (
									<Field
										field={field}
										error={errors[field.name]?.message as string}
										placeholder="Название"
									/>
								)}
							/>

							<Controller
								name="subtitle"
								control={control}
								defaultValue={data.subtitle}
								rules={{
									required: 'Подзаголовок обязательно',
								}}
								render={({ field, fieldState }) => (
									<Field
										field={field}
										error={errors[field.name]?.message as string}
										placeholder="Подзаголовок"
									/>
								)}
							/>

							<Controller
								name="description"
								defaultValue={data.description}
								control={control}
								rules={{
									required: 'Описание обязательно',
								}}
								render={({ field, fieldState }) => (
									<Field
										height={200}
										as={'textarea'}
										field={field}
										error={errors[field.name]?.message as string}
										placeholder="Описание"
									/>
								)}
							/>

							<Form.Group controlId="uploader">
								<Form.ControlLabel>Постер</Form.ControlLabel>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
								>
									<Whisper
										open
										placement="top"
										speaker={<Tooltip visible>Текущий постер</Tooltip>}
									>
										<img
											title="Текущий потсер"
											src={imgHostPath(data.poster)}
											alt={data.poster}
											width={60}
											height={76}
											style={{ borderRadius: '5px' }}
										/>
									</Whisper>
									<Form.Control
										disabled={files.length > 0}
										multiple={false}
										listType="picture"
										name="file"
										accepter={Uploader}
										withCredentials
										action={`${APP_URI}/upload/product-poster`}
										defaultValue={imgHostPath(data.poster)}
										onChange={(file) => setFiles(file)}
										onUpload={async (file, response, xhr) => {
											xhr.onloadend = async () => {
												const data = await JSON.parse(xhr.response)
												setUploadResponse(data)
												toaster.push(
													<Message type="success" showIcon>
														{data.message}
													</Message>
												)
											}
											xhr.onerror = (error) => {
												toaster.push(
													<Message type="error" showIcon>
														Произошла ошибка при загрузке файла. <br />
														Повторите попытку.
													</Message>
												)
											}
										}}
									/>
								</div>
							</Form.Group>

							<FormButtons
								disabled={!errors}
								isLoading={loadingCreated}
								type="update"
							/>
						</Form>
					</Col>

					<Col xs={12}>
						<Heading
							style={{ margin: '12px 0px' }}
							type="h5"
							children="Категории товара"
						/>
						<SelectedCategory
							popOverText={
								<>
									<Text muted>
										При обновлении <br /> не обязательно выбирать категорию{' '}
										<br />
										Выбирайте, если решили обновить категории товара
									</Text>
								</>
							}
							categoryIds={categoryIds}
							setCategoryIds={setCategoryIds}
						/>
					</Col>
				</Row>
			</Grid>
		)
	)
}

export default UpdateProduct
