import { FC, useState } from 'react'
import { Pagination as PaginationCMP } from 'rsuite'
import { IPaginationProps } from './Pagination.props'

export const Pagination: FC<IPaginationProps> = ({ data }) => {
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(1)

	const totalData = data?.filter((v, i) => {
		const start = limit * (page - 1)
		const end = start + limit
		return i >= start && i < end
	})

	const handleChangeLimit = (dataKey: number) => {
		setPage(1)
		setLimit(dataKey)
	}

	return (
		<div style={{ padding: 20 }}>
			<PaginationCMP
				prev
				next
				first
				last
				size="xs"
				total={totalData?.length || 10}
				limit={limit}
				activePage={page}
				onChangePage={setPage}
				onChangeLimit={handleChangeLimit}
			/>
		</div>
	)
}
