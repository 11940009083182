import { useQuery } from '@tanstack/react-query'
import ruRU from 'date-fns/locale/ru'
import { FC } from 'react'
import {
	Calendar,
	Col,
	Divider,
	Grid,
	Panel,
	Placeholder,
	Row,
	Text,
	Timeline,
	VStack,
} from 'rsuite'
import { Heading } from '../../components/heading/Heading'
import { useUser } from '../../hooks/user/useUser'
import { StatService } from '../../services/stat/Stat.service'
import UsersTable from '../users/UsersTable'
import style from './home.module.scss'

const Home: FC = () => {
	const { users } = useUser()

	const { data, isLoading } = useQuery({
		queryKey: ['getDailyStat'],
		queryFn: () => StatService.getDailyStat(),
	})

	return (
		<>
			<section>
				<div className="hero">
					<Grid fluid>
						<Row gutter={30}>
							<Col xs={12}>
								<Panel className={style.panel}>
									<VStack spacing={25}>
										<Text size={'lg'} color="yellow">
											Обновление системы
										</Text>
										<Timeline align="left">
											<Timeline.Item>Было обновлено ядро UI</Timeline.Item>
											<Timeline.Item>
												Были испрвлены ошибки ядра UI
											</Timeline.Item>
											<Timeline.Item>
												Исправлены ошибки с созданием товаров и переработаны
												баги, ошибки, и сделано многое другое.
											</Timeline.Item>
											<Timeline.Item>Проработано ядро</Timeline.Item>
											<Timeline.Item>Ожидается обновление 0.1.4</Timeline.Item>
										</Timeline>
									</VStack>
								</Panel>
							</Col>
							<Col xs={12} style={{ overflow: 'hidden' }}>
								<Panel className={style.panel}>
									{isLoading ? (
										<Placeholder.Graph active></Placeholder.Graph>
									) : (
										<>
											<Text
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '10px',
												}}
											>
												Зарегистрировано пользователей сегодня:
												<strong
													style={{ fontSize: '12px', fontWeight: 'bold' }}
												>
													{data?.users}
												</strong>
											</Text>
										</>
									)}
								</Panel>
							</Col>
						</Row>
						<Divider />
						<Row gutter={30}>
							<Col xs={24}>
								<Panel
									className={style.panel}
									header={<Heading type="h5">Пользователи</Heading>}
								>
									<UsersTable isLoading={isLoading} users={users} />
								</Panel>
							</Col>
						</Row>
						<Row>
							<Col>
								<Calendar
									compact
									isoWeek
									bordered
									locale={{
										dateLocale: ruRU,
										today: 'Сегодня',
										formattedMonthPattern: 'MMM yyyy',
										formattedDayPattern: 'dd MMM yyyy',
									}}
								/>
							</Col>
						</Row>
					</Grid>
				</div>
			</section>
		</>
	)
}

export default Home
