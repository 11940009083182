import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoPricetag } from 'react-icons/io5'
import { MdCountertops, MdDiscount } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import {
	Col,
	Divider,
	Form,
	Grid,
	Input,
	InputGroup,
	InputNumber,
	Message,
	Row,
	Toggle,
	Tooltip,
	useToaster,
	Whisper,
} from 'rsuite'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Heading } from '../../../components/heading/Heading'
import { IVariantDTO } from '../../../services/product/dto'
import { ProductService } from '../../../services/product/Product.service'
import { CreateColorPage } from '../color/CreateColor'
import { CreateSpecification } from '../specifications/CreateSpecifications'
import style from './variant.module.scss'

export const CreateVariantPage = () => {
	const { id } = useParams<{ id: string }>()
	const [variantId, setVariantId] = useState<number>()
	const [isHit, setIsHit] = useState(false)
	const [isNew, setIsNew] = useState(false)
	const toaster = useToaster()

	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['createProductVariant'],
		mutationFn: (dto: IVariantDTO) => ProductService.createVariant(dto),
	})

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<IVariantDTO>({
		mode: 'all',
	})

	const createHandle = async (values: IVariantDTO) => {
		try {
			await mutateAsync(
				{
					...values,
					productId: Number(id),
					discount: values.discount ? Number(values.discount) : undefined,
					stock: Number(values.stock),
					isHit,
					isNew,
				},
				{
					onSuccess(data, variables, context) {
						setVariantId(data.variantId)
						toaster.push(
							<Message showIcon type="success">
								{data.message}
							</Message>
						)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{error}
				</Message>
			)
		}
	}

	return (
		<Grid fluid>
			<Row className={style.row}>
				<Col xs={16} className={style.col}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Добавить вариант"
					/>
					<Divider />
					<Form
						layout="horizontal"
						// @ts-ignore
						onSubmit={handleSubmit(createHandle)}
					>
						<Controller
							name="price"
							control={control}
							rules={{ required: 'Цена обязательно' }}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<IoPricetag />
										</InputGroup.Addon>
										<InputNumber
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											min={80}
											max={200000}
											placeholder="Цена"
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						<Controller
							name="stock"
							control={control}
							rules={{ required: 'Введите количество на складе' }}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<MdCountertops />
										</InputGroup.Addon>
										<InputNumber
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											min={0}
											placeholder="Количество на складе"
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						<Controller
							name="discount"
							control={control}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<MdDiscount />
										</InputGroup.Addon>
										<InputNumber
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											min={3}
											max={20}
											placeholder="Скидка"
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						<Controller
							name="size"
							control={control}
							render={({ field, fieldState }) => (
								<Whisper
									trigger={'hover'}
									placement="right"
									speaker={
										<Tooltip visible>
											Если это не одежда, то проигнорируйте поле
										</Tooltip>
									}
								>
									<Form.Group>
										<InputGroup>
											<InputGroup.Addon>
												<MdDiscount />
											</InputGroup.Addon>
											<Input
												ref={field.ref}
												name={field.name}
												onChange={field.onChange}
												placeholder="Размер"
											/>
										</InputGroup>

										<Form.ErrorMessage
											show={!!fieldState.error}
											placement="bottomStart"
										>
											{fieldState.error?.message}
										</Form.ErrorMessage>
									</Form.Group>
								</Whisper>
							)}
						/>

						<Form.Group>
							<Toggle size={'sm'} color={'violet'} onChange={setIsHit}>
								Хит
							</Toggle>
						</Form.Group>

						<Form.Group>
							<Toggle size={'sm'} color={'violet'} onChange={setIsNew}>
								Новинка
							</Toggle>
						</Form.Group>

						<FormButtons
							disabled={!isValid}
							isLoading={isLoading}
							type="submit"
						/>
					</Form>
				</Col>
			</Row>
			<Row className={style.row}>
				<Col xs={16} className={style.col}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Цвет варианта"
					/>
					<Divider />
					<CreateColorPage variantId={variantId} />
				</Col>
			</Row>
			<Row className={style.row}>
				<Col xs={16} className={style.col}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Спецификации"
					/>
					<Divider />
					<CreateSpecification variantId={variantId} />
				</Col>
			</Row>
		</Grid>
	)
	// return <VariantForm handle={createHandle} isLoading={isLoading} />
}
