import { useMutation } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Message, Panel, Stack, toaster, VStack } from 'rsuite'
import { IUserLoginDTO } from '../../api/data-transfer/user.dto'
import { Field } from '../../components/form/field/Field'
import { AuthEnumName } from '../../enum/auth.enum'
import { saveItemToLocalStorage } from '../../helpers/localstorage.helper'
import { useStoreActions } from '../../hooks/useStoreActions'
import { AuthService } from '../../services/auth/Auth.service'

const AuthPage = () => {
	const navigate = useNavigate()
	const { setAuthUser } = useStoreActions()
	const { mutateAsync, isLoading } = useMutation(
		['loginUser'],
		(data: IUserLoginDTO) => AuthService.login(data)
	)

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IUserLoginDTO>({
		mode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
		},
	})

	const loginHandler = async (data: IUserLoginDTO) => {
		try {
			await mutateAsync(data, {
				onSuccess(data, variables, context) {
					setAuthUser()
					saveItemToLocalStorage(AuthEnumName.ACCESS_TOKEN, data.accessToken)
					saveItemToLocalStorage(AuthEnumName.REFRESH_TOKEN, data.refreshToken)
					navigate('/dashboard')
				},
			})
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}

	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			style={{ height: '100vh' }}
		>
			<Panel header="Войти в систему" bordered style={{ width: 500 }}>
				<Form
					fluid
					// @ts-ignore
					onSubmit={handleSubmit(loginHandler)}
				>
					<Form.Group>
						<Form.ControlLabel>E-mail</Form.ControlLabel>
						<Controller<IUserLoginDTO>
							name="email"
							control={control}
							render={({ field, fieldState, formState }) => (
								<Field
									field={field}
									error={fieldState.error?.message}
									style={{ height: '50px' }}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.ControlLabel>Пароль</Form.ControlLabel>
						<Controller
							name="password"
							control={control}
							render={({ field, fieldState, formState }) => (
								<Field
									field={field}
									style={{ height: '50px' }}
									error={fieldState.error?.message}
									autoComplete="off"
								/>
							)}
						/>
					</Form.Group>

					<VStack spacing={10} alignItems="center">
						<Button
							loading={isLoading}
							color="violet"
							appearance="primary"
							block
							type="submit"
							style={{ padding: '16px', borderRadius: '15px' }}
						>
							Войти
						</Button>

						<Button appearance="link" color="violet">
							Забыли пароль?
						</Button>
					</VStack>
				</Form>
			</Panel>
		</Stack>
	)
}

export default AuthPage
