import { useMutation } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MdDescription, MdTitle } from 'react-icons/md'
import { Form, InputGroup, Message, Text, toaster } from 'rsuite'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import {
	ICreateSpecificationDTO,
	SpecificationDTO,
} from '../../../services/product/dto'
import { ProductService } from '../../../services/product/Product.service'

export const CreateSpecification: FC<{ variantId?: number }> = ({
	variantId,
}) => {
	const { mutateAsync, isLoading, isSuccess } = useMutation({
		mutationKey: ['createSpecification'],
		mutationFn: (dto: ICreateSpecificationDTO) =>
			ProductService.createSpecification(dto),
	})

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm<SpecificationDTO>({
		mode: 'onChange',
	})

	const createHandle = async (values: SpecificationDTO) => {
		try {
			if (variantId) {
				await mutateAsync(
					{
						specifications: [{ ...values, variantId }],
					},
					{
						onSuccess(data, variables, context) {
							toaster.push(
								<Message type="success" showIcon>
									{data.message}
								</Message>
							)
						},
					}
				)
			}
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}

	useEffect(() => {
		if (isSuccess) {
			reset()
		}
	}, [isSuccess])

	return !variantId ? (
		<Text as="q" color="blue">
			Чтобы добавить спецификации,создайте вариант
		</Text>
	) : (
		<Form
			layout="vertical"
			// @ts-ignore
			onSubmit={handleSubmit(createHandle)}
		>
			<Controller
				name="name"
				control={control}
				rules={{ required: 'Название спецификации' }}
				render={({ field, fieldState }) => (
					<Form.Group>
						<InputGroup>
							<InputGroup.Addon>
								<MdTitle />
							</InputGroup.Addon>
							<Field name={field.name} inputRef={field.ref} field={field} />
						</InputGroup>

						<Form.ErrorMessage
							show={!!fieldState.error}
							placement="bottomStart"
						>
							{fieldState.error?.message}
						</Form.ErrorMessage>
					</Form.Group>
				)}
			/>
			<Controller
				name="value"
				control={control}
				rules={{ required: 'Значение спецификации' }}
				render={({ field, fieldState }) => (
					<Form.Group>
						<InputGroup>
							<InputGroup.Addon>
								<MdDescription />
							</InputGroup.Addon>
							<Field name={field.name} inputRef={field.ref} field={field} />
						</InputGroup>

						<Form.ErrorMessage
							show={!!fieldState.error}
							placement="bottomStart"
						>
							{fieldState.error?.message}
						</Form.ErrorMessage>
					</Form.Group>
				)}
			/>
			<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
		</Form>
	)
	// return <SpecificationForm handle={createHandle} isLoading={isLoading} />
}
