import apiBase from '../../api/AxiosBase'
import { IPromotion } from '../../interfaces/promotion.interface'
import {
	IAddProductsDTO,
	ICreatePromoDTO,
	IGeneratePromotionDataDTO,
	IRemoveProductDTO,
	IUpdatePromoDTO,
} from './dto'

const PATH = '/promo'
export const PromotionService = {
	/**
	 *
	 * @param data
	 * @returns
	 */
	async create(data: ICreatePromoDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}`,
			method: 'POST',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param data
	 * @returns
	 */
	async update(id: number, data: IUpdatePromoDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}/${id}`,
			method: 'PATCH',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param data
	 * @returns
	 */
	async addProducts(id: number, data: IAddProductsDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}/add-products/${id}`,
			method: 'POST',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param productId
	 * @returns DELETED PRODUCT FROM PROMO
	 */
	async removeProduct(data: IRemoveProductDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}/remove-product`,
			method: 'POST',
			data,
		})
		return response.data
	},
	/**
	 *
	 * @returns
	 */
	async fetchAll() {
		const response = await apiBase<IPromotion[]>({
			url: `${PATH}`,
			method: 'GET',
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchById(id?: number) {
		if (id) {
			const response = await apiBase<IPromotion>({
				url: `${PATH}/by-id/${id}`,
				method: 'GET',
			})
			return response.data
		}
		return null
	},

	/**
	 *
	 * @param data
	 * @returns
	 * @method generatePromotionData
	 */
	async generatePromotionData(data: IGeneratePromotionDataDTO) {
		const response = await apiBase<{ discount: number }>({
			url: `${PATH}/generate`,
			method: 'POST',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async changeActivity(id: number, data: { value: boolean }) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}/update-active/${id}`,
			method: 'PATCH',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param path
	 * @returns
	 */
	async deleteImage(data: { id: number; path: string }) {
		const response = await apiBase<{ message: string }>({
			url: `${PATH}/image`,
			method: 'DELETE',
			data,
		})
		return response.data
	},
}
