import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Container, Content, CustomProvider, Modal } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { DialogComponent } from '../components/dialog/Dialog'
import Header from '../components/header/Header'
import Sidebar from '../components/sidebar/Sidebar'
import { SidebarDrawer } from '../components/sidebar/sidebar-drawer/Sidebar-mobile'
import { useUser } from '../hooks/user/useUser'
import '../styles/index.scss'

const RootLayout = () => {
	const [expand, setExpand] = useState(false)
	const { user } = useUser()

	return user ? (
		<CustomProvider theme="dark">
			<Container>
				<Sidebar />
				<SidebarDrawer collapsed={expand} setCollapsed={setExpand} />
				<Container>
					<Header collapsed={expand} setCollapsed={setExpand} />
					<Content className="content">
						<Outlet />
					</Content>
				</Container>
				<Modal />
				<DialogComponent />
			</Container>
		</CustomProvider>
	) : (
		<Navigate to={'/'} />
	)
}

export default RootLayout
