// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forrbbidden_forrbbidden__bAMi5 {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/404/forrbbidden.module.scss"],"names":[],"mappings":"AAEA;EACC,UAAA;AADD","sourcesContent":["@import '../../styles/mixin';\n@import '../../styles/vars';\n.forrbbidden {\n\tcolor: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forrbbidden": `forrbbidden_forrbbidden__bAMi5`
};
export default ___CSS_LOADER_EXPORT___;
