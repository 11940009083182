import { FC, useEffect, useState } from 'react'
import { Grid, Popover, Row, SelectPicker, Whisper } from 'rsuite'
import { useFetchAllMainCategories } from '../../../hooks/categories/useFetchAllMainCategories'
import { CategoryService } from '../../../services/category/Category.service'
import { ICategories, IProductFormProps } from './ProductForm.props'

export const SelectedCategory: FC<IProductFormProps> = ({
	setCategoryIds,
	categoryIds,
	popOverText,
}) => {
	const [open, setOpen] = useState(true)
	const [categories, setCategories] = useState<ICategories>({
		child: undefined,
		parent: undefined,
	})

	const { data, isLoading } = useFetchAllMainCategories()

	useEffect(() => {
		const fetchParents = async (id: number) => {
			try {
				const parents = await CategoryService.fetchByParentId(id)
				if (parents.length) {
					return setCategories((prev) => ({ ...prev, parent: parents }))
				} else {
					setCategories((prev) => ({ ...prev, parent: undefined }))
					setCategoryIds((prev) => ({ ...prev, parent: undefined }))
				}
			} catch (error) {
				// message.error(`Произошла ошибка при загрузке подкатегорий ${error}`)
			}
		}
		if (categoryIds.main) {
			fetchParents(+categoryIds.main)
		}
		// eslint-disable-next-line
	}, [categoryIds.main])

	useEffect(() => {
		const fetchChilds = async (id: number) => {
			try {
				const childs = await CategoryService.fetchByParentId(id)
				if (childs.length) {
					return setCategories((prev) => ({ ...prev, child: childs }))
				}
			} catch (error) {
				// message.error(`Произошла ошибка при загрузке подкатегорий ${error}`)
			}
		}
		if (categoryIds.parent) {
			fetchChilds(+categoryIds.parent)
		}
	}, [categoryIds.parent])

	useEffect(() => {
		if (!categories.parent) {
			setCategories((prev) => ({ ...prev, child: undefined }))
			setCategoryIds((prev) => ({ ...prev, child: undefined }))
		}
		// eslint-disable-next-line
	}, [categories.parent])

	return (
		<Grid fluid>
			<Row style={{ marginBottom: '10px' }}>
				<Whisper
					open={open}
					placement={'bottomStart'}
					speaker={<Popover>{popOverText}</Popover>}
				>
					<div style={{ position: 'relative' }}>
						<SelectPicker
							loading={isLoading}
							placeholder="Родительская категория"
							searchable={false}
							data={
								data?.map((c) => ({
									label: c.name,
									value: c.id,
								})) || []
							}
							block
							onSelect={(value) => {
								setCategoryIds((prev) => ({ ...prev, main: value }))
							}}
							onOpen={() => setOpen(false)}
						/>
					</div>
				</Whisper>
			</Row>
			<Row style={{ marginBottom: '10px' }}>
				{categories.parent ? (
					<SelectPicker
						placeholder="Подкатегория"
						searchable={false}
						data={categories.parent.map((category) => ({
							key: category.id,
							label: category.name,
							value: category.id,
						}))}
						block
						onSelect={(value) =>
							setCategoryIds({ ...categoryIds, parent: value })
						}
					/>
				) : null}
			</Row>
			<Row>
				{categories.child?.length ? (
					<SelectPicker
						placeholder="Подкатегории"
						searchable={false}
						data={categories.child.map((childs) => ({
							key: childs.id,
							label: childs.name,
							value: childs.id,
						}))}
						block
						onSelect={(value) =>
							setCategoryIds({ ...categoryIds, child: value })
						}
					/>
				) : null}
			</Row>
		</Grid>
	)
}
