import apiBase from '../../api/AxiosBase'

export const UploadService = {
	/**
	 *
	 * @param data
	 * @param destinationPath
	 * @param setValue
	 * @param multi
	 * @returns
	 */
	async upload(
		data: FormData,
		destinationPath?: string,
		setValue?: (value: number) => void,
		multi?: boolean
	) {
		return await apiBase<{
			filePath: string
			message: string
			paths?: string[]
		}>({
			url: destinationPath
				? `/upload/${multi ? `multi/${destinationPath}` : destinationPath}`
				: `/upload/${multi ? 'multi' : ''}`,
			method: 'Post',
			data,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: (event) => {
				if (setValue) {
					// @ts-ignore
					const progerss = (event.loaded / event.total) * 100
					setValue(Math.ceil(progerss))
				}
			},
		})
	},

	/**
	 *
	 * @returns
	 */
	async findAllImages() {
		const response = await apiBase<{ paths: string[]; totalSize: number }>({
			url: '/upload',
			method: 'Get',
		})
		return response.data
	},

	/**
	 *
	 * @param path
	 * @returns
	 */
	async deleteFile(path: string) {
		const response = await apiBase<{ message: string }>({
			url: '/upload',
			method: 'Delete',
			data: { path },
		})
		return response.data
	},
}
