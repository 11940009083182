import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, Drawer, Nav } from 'rsuite'
import { MENU_DATA } from '../menu.data'
import { ISidebarMobileProps } from './Sidebar-mobile.props'
import style from './sidebar-mobile.module.scss'

export const SidebarDrawer: FC<ISidebarMobileProps> = ({
	collapsed,
	setCollapsed,
}) => {
	return (
		<Drawer
			className={style.drawer}
			size={'xs'}
			placement={'left'}
			open={collapsed}
			onClose={() => setCollapsed(false)}
		>
			<Drawer.Header>
				<Drawer.Title>Drawer Title</Drawer.Title>
				<Drawer.Actions>
					<Button onClick={() => setCollapsed(false)}>Cancel</Button>
				</Drawer.Actions>
			</Drawer.Header>
			<Drawer.Body>
				<Nav className={style.nav}>
					{MENU_DATA.map(({ Icon, name, to }, index) => (
						<Nav.Item
							key={index}
							eventKey={`${index}`}
							className={style.navItem}
						>
							<Link to={to}>
								<span>{Icon}</span>
								{name}
							</Link>
						</Nav.Item>
					))}
				</Nav>
			</Drawer.Body>
		</Drawer>
	)
}
