import { Navigate, Outlet } from 'react-router-dom'
import { CustomProvider } from 'rsuite'
import Header from '../components/header/Header'
import { useUser } from '../hooks/user/useUser'
import '../styles/index.scss'

const AuthLayout = () => {
	const { user } = useUser()
	return user ? (
		<Navigate to={'/dashboard'} />
	) : (
		<CustomProvider theme="dark">
			<Header />
			<div className="root">
				<main className="auth-main">
					<Outlet />
				</main>
			</div>
		</CustomProvider>
	)
}

export default AuthLayout
