import { FC } from 'react'
import { Heading as HeadingCMP } from 'rsuite'
import { IHeadingProps } from './Heading.props'

export const Heading: FC<IHeadingProps> = ({
	type,
	children,
	className,
	...props
}) => {
	switch (type) {
		case 'h1':
			return (
				<HeadingCMP {...props} level={1} className={className}>
					{children}
				</HeadingCMP>
			)
		case 'h2':
			return (
				<HeadingCMP {...props} level={2} className={className}>
					{children}
				</HeadingCMP>
			)
		case 'h3':
			return (
				<HeadingCMP {...props} level={3} className={className}>
					{children}
				</HeadingCMP>
			)
		case 'h4':
			return (
				<HeadingCMP {...props} level={4} className={className}>
					{children}
				</HeadingCMP>
			)
		case 'h5':
			return (
				<HeadingCMP {...props} level={5} className={className}>
					{children}
				</HeadingCMP>
			)
		case 'h6':
			return (
				<HeadingCMP {...props} level={6} className={className}>
					{children}
				</HeadingCMP>
			)
		default:
			return (
				<HeadingCMP {...props} level={6} className={className}>
					{children}
				</HeadingCMP>
			)
	}
}
