import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { MdDescription, MdTitle } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Col,
	Divider,
	Form,
	Grid,
	InputGroup,
	Message,
	Row,
	toaster,
} from 'rsuite'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { Heading } from '../../../components/heading/Heading'
import { LoaderComponent } from '../../../components/loader/Loader'
import { ProductService } from '../../../services/product/Product.service'
import { IUpdateSpecificationDTO } from '../../../services/product/dto'
import style from '../variant/variant.module.scss'

export const UpdateSpecification = () => {
	const { id } = useParams<{ id: string }>()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const { mutateAsync, isLoading: updateLoading } = useMutation({
		mutationKey: ['updateSpecification'],
		mutationFn: (dto: IUpdateSpecificationDTO) =>
			ProductService.updateSpecification(dto),
	})

	const { data: oldData, isLoading } = useQuery({
		queryKey: ['fetchSpecificationById'],
		queryFn: () => ProductService.fetchSpecificationByVariantId(Number(id)),
		cacheTime: 0,
		enabled: !!id,
	})

	const {
		handleSubmit,
		control,
		setValue,
		formState: { isValid },
	} = useForm<{ specifications: IUpdateSpecificationDTO[] }>({
		mode: 'onChange',

		values: {
			specifications: oldData || [],
		},
	})

	const { fields } = useFieldArray({
		control,
		name: 'specifications',
		keyName: 'id',
	})

	const updateHandle = async (values: IUpdateSpecificationDTO) => {
		try {
			await mutateAsync(values, {
				onSuccess(data, variables, context) {
					toaster.push(
						<Message type="success" showIcon>
							{data.message}
						</Message>
					)
					queryClient.invalidateQueries(['fetchProductBySlug'])
					navigate(-1)
				},
			})
		} catch (error) {
			toaster.push(
				<Message type="success" showIcon>
					{error}
				</Message>
			)
		}
	}

	console.log(fields)
	return isLoading ? (
		<LoaderComponent />
	) : oldData ? (
		<Grid fluid>
			<Row className={style.row}>
				<Col xs={12} className={style.col}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Обновить спецификации"
					/>
					<Divider />
					<Form
						layout="horizontal"
						// @ts-ignore
						onSubmit={handleSubmit(updateHandle)}
					>
						<Grid fluid>
							{fields.map((field, index) => (
								<Row gutter={20} key={field.id} style={{ margin: '10px 0px' }}>
									<Col xs={12}>
										<Controller
											// @ts-ignore
											name={`specifications.${index}.name`}
											control={control}
											rules={{ required: 'Название спецификации' }}
											render={({ field, fieldState }) => (
												<Form.Group>
													<InputGroup>
														<InputGroup.Addon>
															<MdTitle />
														</InputGroup.Addon>
														<Field field={field} />
													</InputGroup>

													<Form.ErrorMessage
														show={!!fieldState.error}
														placement="bottomStart"
													>
														{fieldState.error?.message}
													</Form.ErrorMessage>
												</Form.Group>
											)}
										/>
									</Col>
									<Col xs={12}>
										<Controller
											// @ts-ignore
											name={`specifications.${index}.value`}
											control={control}
											rules={{ required: 'Описание спецификации' }}
											render={({ field, fieldState }) => (
												<Form.Group>
													<InputGroup>
														<InputGroup.Addon>
															<MdDescription />
														</InputGroup.Addon>
														<Field field={field} />
													</InputGroup>

													<Form.ErrorMessage
														show={!!fieldState.error}
														placement="bottomStart"
													>
														{fieldState.error?.message}
													</Form.ErrorMessage>
												</Form.Group>
											)}
										/>
									</Col>
								</Row>
							))}
						</Grid>
						<FormButtons
							disabled={!isValid}
							isLoading={isLoading}
							type="update"
						/>
					</Form>
				</Col>
			</Row>
		</Grid>
	) : null
}
