import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	Button,
	ButtonGroup,
	Col,
	Grid,
	List,
	Message,
	Panel,
	PanelGroup,
	SelectPicker,
	Stack,
	Text,
	toaster,
} from 'rsuite'
import { Heading } from '../../../components/heading/Heading'
import { LoaderComponent } from '../../../components/loader/Loader'
import { EmptyComponent } from '../../../components/loader/empty/Empty'
import { EnumStatusOrder } from '../../../enum/Order.enum'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useWebSocket } from '../../../hooks/ws/useWebsocket'
import { OrderService } from '../../../services/orders/Order-service'
import { IChangeStatusOrderDTO } from '../../../services/orders/dto'
import style from './order.module.scss'

export const OrderPage = () => {
	const { id } = useParams<{ id: string }>()
	const [selectedValue, setSelectedValue] = useState<
		IChangeStatusOrderDTO | undefined
	>()
	const queryClient = useQueryClient()
	const socket = useWebSocket()

	const { data, isLoading } = useQuery({
		queryKey: ['fetchOrderById'],
		queryFn: () => OrderService.fetchById(Number(id)),
		enabled: !!id,
	})

	const { mutateAsync, isLoading: loading } = useMutation({
		mutationKey: ['changeStatusOrder'],
		mutationFn: (data: IChangeStatusOrderDTO) =>
			OrderService.changeStatus(data),
	})

	const changeStatus = async () => {
		try {
			if (!selectedValue) {
				toaster.push(
					<Message showIcon type="info">
						Выберите статус
					</Message>
				)
				return
			}
			await mutateAsync(selectedValue, {
				onSuccess(data, variables, context) {
					toaster.push(
						<Message showIcon type="info">
							{data.messages.admin}
						</Message>
					)
					toaster.push(
						<Message showIcon type="info">
							{data.messages.order}
						</Message>
					)
					queryClient.invalidateQueries({ queryKey: ['fetchOrderById'] })
				},
			})
			if (socket) {
				socket?.emit('sendNotification', {
					message: `Ваш заказ № - ${data?.orderId} ожидает Вас.`,
				})
			} else {
				toaster.push(
					<Message showIcon type="info">
						Socket не подключен!
					</Message>
				)
			}
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{error}
				</Message>
			)
		}
	}

	console.log(data)
	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<PanelGroup>
			<Panel
				bordered
				defaultExpanded
				header={
					<Stack justifyContent="space-between">
						<Heading type="h5" style={{ display: 'flex', gap: '12px' }}>
							Заказ -<Text muted>{data?.orderId}</Text>
						</Heading>
						<Text
							color={
								data.status.includes(EnumStatusOrder.WAITING) ? 'red' : 'green'
							}
						>
							{data?.status}
						</Text>
					</Stack>
				}
			>
				<Grid
					fluid
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Col xs={12}>
						<List bordered>
							{/* <List.Item>
								<Text muted>Склад</Text>
								<Text>{data?.address.name}</Text>
							</List.Item> */}
							<List.Item>
								<Text muted>Адрес доставки</Text>
								<Text>{data?.address.name}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Метод оплаты</Text>
								<Text>{data?.payment_type}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Сумма заказа</Text>
								<Text>{data?.totalCache}</Text>
							</List.Item>
						</List>
					</Col>
					<Col xs={12}>
						<List bordered>
							<List.Item>
								<Text muted>Заказчик</Text>
								<Text>{data?.user.name}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Телефонный номер</Text>
								<Text>{data?.user.phoneNumber}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Email</Text>
								<Text>{data?.user.email}</Text>
							</List.Item>
						</List>
					</Col>
				</Grid>
			</Panel>
			<Panel
				bordered
				header={
					<Heading type="h5" style={{ display: 'flex', gap: '12px' }}>
						Товары -<Text muted>{data?.items.length}шт</Text>
					</Heading>
				}
			>
				<div className={style.products}>
					{data.items.map((product) => (
						<List bordered key={product.id} className={style.product}>
							{/* <List.Item className={style.productItem}>
								<Text muted>Вариант товара</Text>
								<Text color="blue">{product?.title}</Text>
							</List.Item> */}
							<List.Item className={style.productItem}>
								<Text muted>Артикул</Text>
								<Text color="yellow">
									{product.productVariant.articleNumber}
								</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Цвет</Text>
								<Text>{product.productVariant.color.color}</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Постер</Text>
								<img
									src={imgHostPath(product.productVariant.color.images[0])}
									alt={product.productVariant.color.images[0]}
									width={55}
									height={70}
								/>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Цена</Text>
								<Text color="green">{product.productVariant.price}KGS</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Размер</Text>
								<Text color="green">{product.productVariant.size}</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Скидка составляет</Text>
								<Text color="green">{product.productVariant.discount}%</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Осталось на складе</Text>
								<Text
									color={product.productVariant.stock < 1 ? 'red' : 'green'}
								>
									{product.productVariant.stock}
								</Text>
							</List.Item>
							<List.Item className={style.productItem}>
								<Text muted>Заказано</Text>
								<Text
									color={product.productVariant.stock < 1 ? 'red' : 'green'}
								>
									{product.quantity}
								</Text>
							</List.Item>
						</List>
					))}
				</div>
			</Panel>
			<Panel
				header={
					<Stack justifyContent="space-between">
						<Heading type="h5" style={{ display: 'flex', gap: '12px' }}>
							Провести заказ
						</Heading>
					</Stack>
				}
			>
				<Stack spacing={10}>
					<SelectPicker
						disabled={data.status.includes(EnumStatusOrder.PAYED)}
						searchable={false}
						placement="top"
						label="Статус"
						placeholder="Выбрать статус"
						data={Object.values(EnumStatusOrder).map((status) => ({
							label: status,
							value: status,
						}))}
						style={{ width: 224 }}
						onSelect={(value) =>
							setSelectedValue({ id: data.id, status: value })
						}
					/>
					<ButtonGroup>
						<Button
							disabled={data.status.includes(EnumStatusOrder.PAYED)}
							loading={loading}
							appearance="primary"
							color="violet"
							onClick={changeStatus}
						>
							Провести заказ
						</Button>
					</ButtonGroup>
				</Stack>
				<Text muted style={{ margin: '10px 0' }} size={'sm'}>
					{!data.status.includes(EnumStatusOrder.PAYED)
						? 'Будет отправлено уведомления по E-mail, и в режиме реального времени Push-уведомление'
						: 'Заказ уже был проводен и не подлежит к отмене. Вы можете связаться с владельцем, для разблокировки отмены '}
				</Text>
			</Panel>
		</PanelGroup>
	) : (
		<EmptyComponent />
	)
}
