import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../../interfaces/user.interface'

interface IUserSliceState {
	isAuth?: boolean
	user: IUser | undefined | null
	users: IUser[] | []
}
const initialState: IUserSliceState = {
	isAuth: false,
	users: [],
	user: null,
}
export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		authUser: (state, { payload }: PayloadAction<IUser>) => {
			state.user = payload
		},
		logOutUser: (state) => {
			state.isAuth = false
			state.user = null
		},
		setAuthUser: (state) => {
			state.isAuth = true
		},
		addUsers: (state, { payload }: PayloadAction<IUser[]>) => {
			state.users = payload
		},
	},
	extraReducers: (build) => {},
})

export const userReducer = userSlice.reducer
export const userActions = userSlice.actions
