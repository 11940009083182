import apiBase from '../../api/AxiosBase'
import {
	IUserLoginDTO,
	IUserRegisterDTO,
} from '../../api/data-transfer/user.dto'
import { IAuthResponse, IAuthTokens } from '../../interfaces/user.interface'

export const AuthService = {
	/**
	 *
	 * @param data
	 * @returns REGISTER NEW USER
	 */
	async registerUser(data: IUserRegisterDTO) {
		const response = await apiBase({
			url: '/auth/register',
			method: 'Post',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param data
	 * @returns IAuthTokens
	 */
	async login(data: IUserLoginDTO) {
		const response = await apiBase<IAuthResponse>({
			url: '/auth/login',
			method: 'Post',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @returns IAuthTokens
	 */
	async refreshToken(refreshToken: string) {
		const response = await apiBase<IAuthTokens>({
			url: '/auth/refresh',
			method: 'Post',
			data: { refreshToken },
		})
		return response.data
	},
}
