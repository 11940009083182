import { forwardRef } from 'react'
import { Popover } from 'rsuite'
import { IPopoverProps } from './Popover.props'

export const PopoverWithLoader = forwardRef<HTMLDivElement, IPopoverProps>(
	({ children, ...props }, ref) => {
		return (
			<Popover ref={ref} {...props}>
				{children}
			</Popover>
		)
	}
)
