import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
	ButtonGroup,
	Col,
	Divider,
	Form,
	Grid,
	Message,
	Text,
	toaster,
	Tooltip,
	Whisper,
} from 'rsuite'
import Uploader, { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../../api/AxiosBase'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { LoaderComponent } from '../../../components/loader/Loader'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useDialog } from '../../../hooks/useDialog'
import { IUpdateColorDTO } from '../../../services/product/dto'
import { ProductService } from '../../../services/product/Product.service'

export const UpdateColorPage = () => {
	const { id } = useParams<{ id: string }>()
	const [uploadResponse, setUploadResponse] = useState<{ images: string[] }>({
		images: [],
	})
	const [files, setFiles] = useState<FileType[]>([])
	const queyCleint = useQueryClient()
	const { openDialog } = useDialog()

	const { mutateAsync: deleteFileMutate, isLoading: loadingDeleted } =
		useMutation({
			mutationKey: ['removeColorImage'],
			mutationFn: (data: { id: number; path: string }) =>
				ProductService.removeColorImage({ ...data }),
		})

	const {
		data,
		isLoading: loadingOld,
		refetch,
	} = useQuery({
		queryKey: ['getColorById'],
		queryFn: () => ProductService.fetchColorById(Number(id)),
		enabled: !!id,
	})

	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['createColor'],
		mutationFn: (dto: IUpdateColorDTO) => ProductService.updateColor(dto),
	})

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm<IUpdateColorDTO>({
		mode: 'onChange',
	})

	const updateHandle = async (values: IUpdateColorDTO) => {
		try {
			await mutateAsync(
				{
					...values,
					// @ts-ignore
					images: [...data.images, ...uploadResponse.images],
					colorId: Number(id),
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}

	console.log(files.length)
	const deleteHandle = async (id: number, path: string) => {
		try {
			openDialog({
				async onConfirm() {
					await deleteFileMutate(
						{ id, path },
						{
							onSuccess(data, variables, context) {
								toaster.push(
									<Message showIcon type="success">
										{data.message}
									</Message>
								)
								refetch()
							},
						}
					)
				},
			})
		} catch (error) {}
	}

	useEffect(() => {
		if (data) {
			setValue('color', data.color)
			setFiles((prevState) => {
				return data.images.map((img, index) => ({
					url: imgHostPath(img),
					status: 'finished',
					fileKey: index,
				}))
			})
		}
		// eslint-disable-next-line
	}, [data])

	return loadingOld ? (
		<LoaderComponent />
	) : data ? (
		<Grid>
			<Col xs={12}>
				<Text as="q" size={'lg'}>
					Отредактировать цвет {data.color}
				</Text>
				<Form
					style={{ marginTop: '20px' }}
					// @ts-ignore
					onSubmit={handleSubmit(updateHandle)}
				>
					<Controller
						name="color"
						control={control}
						rules={{ required: 'Введите цвет варианта' }}
						defaultValue={data.color}
						render={({ field, fieldState }) => (
							<Field
								field={field}
								error={errors[field.name]?.message as string}
								placeholder="Цвет"
							/>
						)}
					/>
					<Form.Group
						controlId="uploader"
						style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
					>
						<Form.ControlLabel>Изображения</Form.ControlLabel>
						<Whisper
							open
							placement="right"
							speaker={<Tooltip visible>Текущие изображения</Tooltip>}
						>
							<ButtonGroup>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
								>
									{data.images.map((img, index) => (
										<div style={{ position: 'relative' }} key={index}>
											<img
												style={{ borderRadius: '5px' }}
												src={imgHostPath(img)}
												alt={img}
												width={70}
												height={90}
											/>
											<ButtonActions
												onClick={() => deleteHandle(data.id, img)}
												size="xs"
												appearance="primary"
												action="delete"
												style={{
													position: 'absolute',
													right: '3px',
													bottom: '3px',
												}}
											/>
										</div>
									))}
								</div>
							</ButtonGroup>
						</Whisper>

						<Form.Control
							disabled={files.length > 5}
							multiple={false}
							listType="picture"
							name="file"
							accepter={Uploader}
							withCredentials
							action={`${APP_URI}/upload/product-poster`}
							onChange={(file) =>
								setFiles((prevState) => {
									return [...prevState, file]
								})
							}
							onUpload={async (file, response, xhr) => {
								xhr.onloadend = async () => {
									const data: { filePath: string; message: string } =
										await JSON.parse(xhr.response)
									setUploadResponse((prevState) => ({
										images: [...prevState.images, data.filePath],
									}))
									toaster.push(
										<Message type="success" showIcon>
											{data.message}
										</Message>
									)
								}
								xhr.onerror = (error) => {
									toaster.push(
										<Message type="error" showIcon>
											Произошла ошибка при загрузке файла. <br />
											Повторите попытку.
										</Message>
									)
								}
							}}
						/>
					</Form.Group>
					<Divider />

					<FormButtons
						disabled={!isValid}
						isLoading={isLoading}
						type="submit"
					/>
				</Form>
			</Col>
		</Grid>
	) : null
}
