import { configureStore } from '@reduxjs/toolkit'
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './root.reducers'

const persistedReducer = persistReducer(
	{ key: 'silkValley', storage, blacklist: ['product', 'user.users'] },
	rootReducer
)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, PAUSE, PERSIST, REHYDRATE, PURGE],
			},
		}),
})

export const persist = persistStore(store)
export type TypeRootState = ReturnType<typeof rootReducer>
