import { MdCreate } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { Button, FlexboxGrid, Table } from 'rsuite'
import { ButtonActions } from '../../components/buttonActions/ButtonActions'
import { Heading } from '../../components/heading/Heading'
import { LoaderComponent } from '../../components/loader/Loader'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { Pagination } from '../../components/pagination/Pagination'
import { imgHostPath } from '../../helpers/imgHostPath'
import { useFetchAllProducts } from '../../hooks/product/useFetchAllProducts'
import { IProduct } from '../../interfaces/product.interface'

const { Column, HeaderCell, Cell } = Table

const ProductsPage = () => {
	const navigate = useNavigate()

	const { data, isLoading } = useFetchAllProducts()

	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<>
			<FlexboxGrid>
				<FlexboxGrid.Item colspan={3}>
					<Heading type="h4" children={'Товары'} className="section-title" />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={3}>
					<Button
						size="md"
						appearance="primary"
						color="violet"
						startIcon={<MdCreate />}
						onClick={() => navigate(`/dashboard/products/create`)}
					>
						Добавить товар
					</Button>
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<div className="table">
				<Table loading={isLoading} height={370} data={data} id="table">
					<Column width={80} align="center">
						<HeaderCell>Постер</HeaderCell>
						<Cell style={{ padding: 0 }} dataKey="poster">
							{(rowData: IProduct) => (
								<img
									src={imgHostPath(rowData.poster)}
									width="40"
									alt={rowData.title}
								/>
							)}
						</Cell>
					</Column>

					<Column width={240}>
						<HeaderCell>Название</HeaderCell>
						<Cell dataKey="title" />
					</Column>

					<Column width={200}>
						<HeaderCell>Подзаголовок</HeaderCell>
						<Cell dataKey="subtitle" />
					</Column>

					<Column width={200}>
						<HeaderCell>Описание</HeaderCell>
						<Cell dataKey="description" />
					</Column>

					<Column width={100}>
						<HeaderCell title="Вариантов товара">[Вариантов]</HeaderCell>
						<Cell dataKey="variants">
							{(rowData: IProduct) => <span>{rowData.variants.length}</span>}
						</Cell>
					</Column>

					<Column width={100}>
						<HeaderCell title="Подробнее">Подробнее</HeaderCell>
						<Cell dataKey="slug">
							{(rowData: IProduct) => (
								<Link to={`/dashboard/products/${rowData.slug}`}>
									<span>Просмотр</span>
								</Link>
							)}
						</Cell>
					</Column>

					<Column width={150} align="center">
						<HeaderCell>Действия</HeaderCell>
						<Cell dataKey="id" style={{ padding: '6px' }}>
							{(rowData: IProduct) => (
								<ButtonActions
									action="update"
									onClick={() =>
										navigate(`/dashboard/products/update/${rowData.slug}`)
									}
								/>
							)}
						</Cell>
					</Column>
				</Table>

				<Pagination data={data} />
			</div>
		</>
	) : (
		<EmptyComponent />
	)
}

export default ProductsPage
