import { FC } from 'react'
import { IoCreate } from 'react-icons/io5'
import { MdDelete, MdUpdate } from 'react-icons/md'
import { Button, Form } from 'rsuite'
import { IFormButtonsProps } from './FormButtons.props'

export const FormButtons: FC<IFormButtonsProps> = ({
	disabled,
	isLoading,
	type,
}) => {
	switch (type) {
		case 'submit':
			return (
				<Form.Group>
					<Button
						startIcon={<IoCreate />}
						loading={isLoading}
						size="lg"
						style={{ width: '100%', height: '50px', borderRadius: '12px' }}
						appearance="primary"
						color="violet"
						type="submit"
						disabled={disabled}
					>
						Добавить
					</Button>
				</Form.Group>
			)

		case 'update':
			return (
				<Form.Group>
					<Button
						startIcon={<MdUpdate />}
						loading={isLoading}
						size="lg"
						style={{ width: '100%', height: '50px', borderRadius: '12px' }}
						appearance="primary"
						color="cyan"
						type="submit"
						disabled={disabled}
					>
						Обновить
					</Button>
				</Form.Group>
			)

		case 'delete':
			return (
				<Form.Group>
					<Button
						startIcon={<MdDelete />}
						loading={isLoading}
						size="lg"
						style={{ width: '100%', height: '50px', borderRadius: '12px' }}
						appearance="primary"
						color="red"
						type="submit"
						disabled={disabled}
					>
						Обновить
					</Button>
				</Form.Group>
			)

		default:
			return (
				<Form.Group>
					<Button
						startIcon={<IoCreate />}
						loading={isLoading}
						size="lg"
						style={{ width: '100%', height: '50px', borderRadius: '12px' }}
						appearance="primary"
						color="violet"
						type="submit"
						disabled={disabled}
					>
						Добавить
					</Button>
				</Form.Group>
			)
	}
}
