import { useMutation } from '@tanstack/react-query'

import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Form, Message, Panel, Text, toaster, Uploader } from 'rsuite'
import { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../../api/AxiosBase'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { PromotionService } from '../../../services/promotion/Promotion.service'
import { ICreatePromoDTO } from '../../../services/promotion/dto'

export const CreatePromoPage = () => {
	const [image, setImage] = useState<any>()
	const [imageSm, setImageSm] = useState<any>()
	const [files, setFiles] = useState<FileType[]>([])
	const navigate = useNavigate()

	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['createPromo'],
		mutationFn: (data: ICreatePromoDTO) => PromotionService.create(data),
	})

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ICreatePromoDTO>({
		mode: 'onChange',
		defaultValues: {
			description: '',
			image: '',
			subtitle: '',
			title: '',
		},
	})

	const createHandle = async (
		values: Omit<ICreatePromoDTO, 'image' | 'imageSm'>
	) => {
		try {
			if (!image && !imageSm) {
				toaster.push(
					<Message type="info" showIcon>
						Выберите изображения
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...values,
					image: image.filePath,
					imageSm: imageSm.filePath,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{String(error)}
				</Message>
			)
		}
	}

	return (
		<Panel header="Создать акцию" style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(createHandle)}
			>
				<Form.Group>
					<Form.ControlLabel>Название</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="title"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.ControlLabel>Подзаголовок</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="subtitle"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.ControlLabel>Описание</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="description"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								as={'textarea'}
								height={200}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>
						Изображение
						<Text size={'md'} color="yellow">
							Должен быть не менее 3000x400
						</Text>
					</Form.ControlLabel>
					<Uploader
						disabled={files.length > 0}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-image`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setImage(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>

				<Form.Group controlId="uploader">
					<Form.ControlLabel>
						Изображение для мобильных устройств
						<Text size={'md'} color="yellow">
							Должен быть не менее 990x400
						</Text>
					</Form.ControlLabel>
					<Uploader
						disabled={files.length > 1}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-image`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setImageSm(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>

				<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
			</Form>
		</Panel>
	)
}
