import { useQuery } from '@tanstack/react-query'
import { ICategory } from '../../interfaces/category.interface'
import { CategoryService } from '../../services/category/Category.service'

export const useFetchAllMainCategories = (initialData?: ICategory[]) => {
	const { data, isLoading } = useQuery({
		queryKey: ['fetchParentsCategory'],
		queryFn: () => CategoryService.fetchParents(),
		initialData: initialData,
	})

	return {
		data,
		isLoading,
	}
}
