import { useEffect, useState } from 'react'
import { UserRoleEnum } from '../../enum/User.enum'
import { useUser } from './useUser'

export const useGetRole = () => {
	const [disable, setDisable] = useState(false)

	const { user } = useUser()

	useEffect(() => {
		if (
			user?.role.includes(UserRoleEnum.OWNER) ||
			user?.role.includes(UserRoleEnum.ADMIN)
		) {
			setDisable(true)
		}
	}, [user])

	return disable
}
