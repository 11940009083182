import apiBase from '../../api/AxiosBase'
import {
	IProduct,
	IProductColor,
	IProductSpecifications,
	IProductVariant,
} from '../../interfaces/product.interface'
import {
	ICreateColorDTO,
	ICreateProductDTO,
	ICreateSpecificationDTO,
	IUpdateColorDTO,
	IUpdateProductDTO,
	IUpdateSpecificationDTO,
	IUpdateVariantDTO,
	IVariantDTO,
} from './dto'

const PRODUCT_PATH = '/product'
export const ProductService = {
	/**
	 *
	 * @returns
	 */
	async fetchAllProducts() {
		const response = await apiBase<IProduct[]>({
			url: `${PRODUCT_PATH}`,
			method: 'GET',
		})
		return response.data
	},

	/**
	 *
	 * @param slug
	 * @returns
	 */
	async fetchBySlug(slug?: string) {
		if (slug) {
			const response = await apiBase<IProduct>({
				url: `${PRODUCT_PATH}/by-slug/${slug}`,
				method: 'GET',
			})
			return response.data
		}
		return null
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchColorById(id?: number) {
		if (id) {
			const response = await apiBase<IProductColor>({
				url: `${PRODUCT_PATH}/variant/color/${id}`,
				method: 'GET',
			})
			return response.data
		}
		return null
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchSpecificationByVariantId(id?: number) {
		if (id) {
			const response = await apiBase<IProductSpecifications[]>({
				url: `${PRODUCT_PATH}/variant/specification/${id}`,
				method: 'GET',
			})
			return response.data
		}
		return null
	},
	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchVariantById(id?: number) {
		if (id) {
			const response = await apiBase<IProductVariant>({
				url: `${PRODUCT_PATH}/variant/${id}`,
				method: 'GET',
			})
			return response.data
		}
		return null
	},

	/**
	 *
	 * @param dto
	 * @returns
	 */
	async create(dto: ICreateProductDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}`,
			method: 'POST',
			data: dto,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param dto
	 * @returns
	 */
	async update(dto: IUpdateProductDTO, id?: number) {
		if (id) {
			const response = await apiBase<{ message: string }>({
				url: `${PRODUCT_PATH}/${id}`,
				method: 'PATCH',
				data: dto,
			})
			return response.data
		}
		return null
	},

	/**
	 *
	 * @param dto
	 * @returns
	 */
	async createVariant(dto: IVariantDTO) {
		const response = await apiBase<{ variantId: number; message: string }>({
			url: `${PRODUCT_PATH}/variant`,
			method: 'POST',
			data: dto,
		})
		return response.data
	},

	/**
	 *
	 * @param dto
	 * @returns
	 */
	async createColor(dto: ICreateColorDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}/variant/color`,
			method: 'POST',
			data: dto,
		})
		return response.data
	},

	/**
	 *
	 * @param dto
	 * @returns
	 */
	async updateColor(dto: IUpdateColorDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}/variant/color`,
			method: 'PUT',
			data: dto,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param path
	 * @returns
	 */
	async removeColorImage({ id, path }: { id: number; path: string }) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}/variant/color/${id}`,
			method: 'DELETE',
			data: { path },
		})
		return response.data
	},
	/**
	 *
	 * @param dto
	 * @returns
	 */
	async createSpecification(dto: ICreateSpecificationDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}/variant/specification`,
			method: 'POST',
			data: dto,
		})
		return response.data
	},

	async updateSpecification(dto: IUpdateSpecificationDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${PRODUCT_PATH}/variant/specification`,
			method: 'PUT',
			data: dto,
		})
		return response.data
	},
	/**
	 *
	 * @param dto
	 * @returns
	 */
	async updateVariant(dto: IUpdateVariantDTO, variantId?: number | null) {
		if (variantId) {
			const response = await apiBase<{ message: string }>({
				url: `${PRODUCT_PATH}/variant/${variantId}`,
				method: 'PATCH',
				data: dto,
			})
			return response.data
		}
		return null
	},
}
