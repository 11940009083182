import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Message, Panel, Stack, toaster, Uploader } from 'rsuite'
import { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../api/AxiosBase'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { LoaderComponent } from '../../components/loader/Loader'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { imgHostPath } from '../../helpers/imgHostPath'
import { useFetchCategoryById } from '../../hooks/categories/useFetchCategoryById'
import { useModal } from '../../hooks/useModal'
import { CategoryService } from '../../services/category/Category.service'
import { ICategoryDTO } from '../../services/category/dto'

export const UpdateCategoryPage = () => {
	const { id } = useParams<{ id: string }>()
	const [image, setImage] = useState<any>()
	const [icon, setIcon] = useState<any>()
	const [files, setFiles] = useState<FileType[]>([])
	const queryClient = useQueryClient()
	const { closeHandler } = useModal()
	const navigate = useNavigate()

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm<ICategoryDTO>({ mode: 'onChange' })

	const { mutateAsync, isLoading } = useMutation(
		['updateMainCategory'],
		(dto: ICategoryDTO) => CategoryService.update(dto, Number(id))
	)

	const { data, isLoading: loadingOldData } = useFetchCategoryById(Number(id))

	const updateHandle = async (dto: ICategoryDTO) => {
		try {
			if (!data) {
				toaster.push(
					<Message type="error" showIcon>
						Выберите медиафайлы
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...dto,
					image: image?.filePath || data.image,
					icon: icon?.filePath || data.icon,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data?.message}
							</Message>
						)
						queryClient.invalidateQueries(['fetchParentsCategory'])
						closeHandler()
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{String(error)}
				</Message>
			)
		}
	}

	useEffect(() => {
		if (data) {
			setValue('name', data.name)
		}
	}, [data])
	return loadingOldData ? (
		<LoaderComponent />
	) : data ? (
		<Panel header={`Обновить категорию ${data.name}`} style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(updateHandle)}
			>
				<Form.Group>
					<Form.ControlLabel>Название категории</Form.ControlLabel>
					<Controller<ICategoryDTO>
						name="name"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>Картинка категории</Form.ControlLabel>
					<Stack spacing={10}>
						<div
							style={{
								display: 'fle',
								justifyContent: 'center',
								alignItems: 'center',
								width: '55px',
								height: '65px',
								background: '#fff',
								borderRadius: '6px',
								overflow: 'hidden',
							}}
						>
							<img
								width={'100%'}
								height={'100%'}
								style={{
									objectFit: 'cover',
								}}
								src={imgHostPath(data.image)}
								alt="image"
							/>
						</div>
						<Uploader
							disabled={files.length > 0}
							multiple={false}
							listType="picture"
							name="file"
							withCredentials
							action={`${APP_URI}/upload/category-image`}
							onChange={(file) => setFiles(file)}
							onUpload={async (file, response, xhr) => {
								xhr.onloadend = async () => {
									const data = await JSON.parse(xhr.response)
									setImage(data)
									toaster.push(
										<Message type="success" showIcon>
											{data.message}
										</Message>
									)
								}
								xhr.onerror = (error) => {
									toaster.push(
										<Message type="error" showIcon>
											Произошла ошибка при загрузке файла. <br />
											Повторите попытку.
										</Message>
									)
								}
							}}
						/>
					</Stack>
				</Form.Group>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>Иконка категории</Form.ControlLabel>
					<Stack spacing={10}>
						<div
							style={{
								display: 'fle',
								justifyContent: 'center',
								alignItems: 'center',
								width: '40px',
								padding: '10px',
								height: '40px',
								background: '#fff',
								borderRadius: '50px',
								overflow: 'hidden',
							}}
						>
							<img
								width={'100%'}
								height={'100%'}
								style={{
									objectFit: 'cover',
								}}
								src={imgHostPath(data.icon)}
								alt="image"
							/>
						</div>
						<Uploader
							multiple={false}
							listType="picture"
							name="file"
							withCredentials
							action={`${APP_URI}/upload/category-icon`}
							onChange={(file) => setFiles(file)}
							onUpload={async (file, response, xhr) => {
								xhr.onloadend = async () => {
									const data = await JSON.parse(xhr.response)
									setIcon(data)
									toaster.push(
										<Message type="success" showIcon>
											{data.message}
										</Message>
									)
								}
								xhr.onerror = (error) => {
									toaster.push(
										<Message type="error" showIcon>
											Произошла ошибка при загрузке файла. <br />
											Повторите попытку.
										</Message>
									)
								}
							}}
						/>
					</Stack>
				</Form.Group>

				<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
			</Form>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
