// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_products__Ohzqn {
  display: grid;
  grid-template-columns: repeat(3, calc(33.3333333333% - 8px));
  column-gap: 12px;
  row-gap: 15px;
}
.order_products__Ohzqn .order_product__s2M4j .order_productItem__LITO4 img {
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/orders/%5Border%5D/order.module.scss","webpack://./src/pages/orders/[order]/order.module.scss"],"names":[],"mappings":"AAIA;EACC,aAAA;EACA,4DAAA;EACA,gBAAA;EACA,aAAA;ACHD;ADMG;EACC,kBAAA;ACJJ","sourcesContent":["@import '../../../styles/media.mixin';\n@import '../../../styles/mixin';\n@import '../../../styles/vars';\n\n.products {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(3, calc(100% / 3 - 12px / 3 * (3 - 1)));\n\tcolumn-gap: 12px;\n\trow-gap: 15px;\n\t.product {\n\t\t.productItem {\n\t\t\timg {\n\t\t\t\tborder-radius: 5px;\n\t\t\t}\n\t\t}\n\t}\n}\n",".products {\n  display: grid;\n  grid-template-columns: repeat(3, calc(33.3333333333% - 8px));\n  column-gap: 12px;\n  row-gap: 15px;\n}\n.products .product .productItem img {\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"products": `order_products__Ohzqn`,
	"product": `order_product__s2M4j`,
	"productItem": `order_productItem__LITO4`
};
export default ___CSS_LOADER_EXPORT___;
