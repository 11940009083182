import apiBase from '../../api/AxiosBase'
import { IStatDailyResponse } from './dto'

const PATH = '/stat'
export const StatService = {
	/**
	 *
	 * @returns IStatDailyResponse
	 */
	async getDailyStat() {
		const response = await apiBase<IStatDailyResponse>({
			url: `${PATH}/daily`,
			method: 'GET',
		})
		return response.data
	},
}
