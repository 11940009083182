import { ReactNode, createContext } from 'react'

export interface IModalContext {
	isActive: boolean
	id?: number | null
	setChaildHandler: (element: ReactNode, id?: number) => void
	closeHandler: () => void
	element: ReactNode
}

const initialContext: IModalContext = {
	isActive: false,
	id: null,
	setChaildHandler: (element, id) => {},
	closeHandler: () => {},
	element: null,
}

export const ModalContext = createContext(initialContext)
