import { useNavigate, useParams } from 'react-router-dom'
import {
	Breadcrumb,
	ButtonGroup,
	Col,
	Grid,
	List,
	Panel,
	PanelGroup,
	Stack,
	Text,
} from 'rsuite'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { Heading } from '../../../components/heading/Heading'
import { LoaderComponent } from '../../../components/loader/Loader'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useFetchProductBySlug } from '../../../hooks/product/useFetchProductBySlug'
import { formatDateString } from '../../../utils/formate-date'
import { Variant } from './[variant]/Variant'

export const ProductPage = () => {
	const { slug } = useParams<{ slug: string }>()
	const navigate = useNavigate()
	const { data, isLoading } = useFetchProductBySlug(slug)

	return isLoading ? (
		<LoaderComponent />
	) : (
		<PanelGroup>
			<Panel
				defaultExpanded
				header={
					<Stack justifyContent="space-between">
						<Heading type="h5">Товар - {data?.title}</Heading>
						<ButtonGroup>
							<ButtonActions
								action="update"
								onClick={() =>
									navigate(`/dashboard/products/update/${data?.slug}`)
								}
							/>
							<ButtonActions action="delete" />
						</ButtonGroup>
					</Stack>
				}
			>
				<Grid fluid style={{ display: 'flex', alignItems: 'center' }}>
					<Col
						xs={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img
							style={{ borderRadius: '5px' }}
							src={imgHostPath(data?.poster || '')}
							width={130}
							height={180}
							alt={data?.title}
						/>
					</Col>
					<Col xs={12}>
						<List bordered>
							<List.Item>
								<Text muted>Подзаголовок</Text>
								<Text>{data?.subtitle}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Описание</Text>
								<Text>{data?.description}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Категория</Text>
								<Breadcrumb>
									{data?.categories.map((c) => (
										<Breadcrumb.Item href={`#`} key={c.category.id}>
											{c.category.name}
										</Breadcrumb.Item>
									))}
								</Breadcrumb>
							</List.Item>
						</List>
					</Col>
					<Col xs={8}>
						<List bordered>
							<List.Item>
								<Text muted>ID</Text>
								<Text>{data?.id}</Text>
							</List.Item>

							<List.Item>
								<Text muted>Дата создания</Text>
								<Text>{formatDateString(data?.createdAt || '')}</Text>
							</List.Item>
							<List.Item>
								<Text muted>Дата обновления</Text>
								<Text>{formatDateString(data?.updatedAt || '')}</Text>
							</List.Item>
						</List>
					</Col>
				</Grid>
			</Panel>

			{/* VARIANTS */}
			<Variant data={data?.variants} productId={data?.id} />
		</PanelGroup>
	)
}
