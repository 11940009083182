import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Button,
	ButtonGroup,
	FlexboxGrid,
	List,
	Message,
	Panel,
	Stack,
	Text,
	toaster,
	Tooltip,
	Whisper,
} from 'rsuite'
import { Heading } from '../../../components/heading/Heading'
import { EmptyComponent } from '../../../components/loader/empty/Empty'
import { LoaderComponent } from '../../../components/loader/Loader'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useFetchAllProducts } from '../../../hooks/product/useFetchAllProducts'
import { IProduct } from '../../../interfaces/product.interface'
import { IAddProductsDTO } from '../../../services/promotion/dto'
import { PromotionService } from '../../../services/promotion/Promotion.service'
import { SelectedProducts } from './[SelectedProducts]'

export const AddProductPage = () => {
	const { id } = useParams<{ id: string }>()
	const queryClient = useQueryClient()
	const [selected, setSelected] = useState<IProduct[]>([])
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()
	const { data: products, isLoading: loadingProducts } = useFetchAllProducts()

	// MUTATION FOR ADD PRODUCTS
	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['addProductsToPromo'],
		mutationFn: (data: IAddProductsDTO) =>
			PromotionService.addProducts(Number(id), data),
	})

	const addProductsHandle = async () => {
		try {
			const data = selected.map((ids) => ids.id)
			await mutateAsync(
				{
					productsIds: data,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message showIcon type="success">
								{data.message}
							</Message>
						)
						queryClient.invalidateQueries(['fetchPromoById'])
						setSelected([])
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{String(error)}
				</Message>
			)
		}
	}

	const selectedHandle = (item: IProduct) => {
		setSelected((prevState) => {
			const isExist = prevState.find((product) => product.id === item.id)
			if (!isExist) {
				return [...prevState, item]
			}
			return prevState
		})
		setOpen(!open)
	}

	const removedHandle = (item: number) => {
		setSelected((prevState) => {
			return prevState.filter((product) => product.id !== item)
		})
	}

	return loadingProducts ? (
		<LoaderComponent />
	) : products ? (
		<>
			<div
				style={{
					position: 'fixed',
					right: '40px',
					top: '18%',
					zIndex: '50',
				}}
			>
				<Button
					startIcon={<MdCheck fontSize={20} />}
					onClick={() => setOpen(true)}
				>
					{selected.length}
				</Button>
			</div>
			<Panel
				header={
					<Stack>
						<Heading type="h5">
							Выберите товары для акции
							<Text muted size={'sm'} weight="light">
								всего - {products.length}
							</Text>
						</Heading>
					</Stack>
				}
			>
				<List hover bordered>
					{products.map((item, index) => {
						const hasDiscount = item.variants.some(
							(variant) => variant.discount && variant.discount > 0
						)
						return (
							<List.Item key={item.id} index={index + 1}>
								<FlexboxGrid align="middle">
									<FlexboxGrid.Item colspan={2}>
										<img
											style={{ objectFit: 'cover', borderRadius: '5px' }}
											width={65}
											height={80}
											src={imgHostPath(item.poster)}
											alt="icon"
										/>
									</FlexboxGrid.Item>

									<FlexboxGrid.Item colspan={8}>
										<Text muted size={'sm'}>
											Название
										</Text>
										<Text>{item.title}</Text>
									</FlexboxGrid.Item>

									<FlexboxGrid.Item colspan={4}>
										<Text muted size={'sm'}>
											Вариантов
										</Text>
										<Text>{item.variants.length}</Text>
									</FlexboxGrid.Item>

									<FlexboxGrid.Item colspan={6}>
										<Whisper
											trigger={'hover'}
											placement="right"
											speaker={
												<Tooltip visible>
													{hasDiscount
														? 'Недоступен. Нет скидок'
														: 'Доступен для выбора'}
												</Tooltip>
											}
										>
											<ButtonGroup>
												<Button
													disabled={hasDiscount}
													onClick={() => selectedHandle(item)}
												>
													<MdCheck fontSize={20} />
												</Button>
											</ButtonGroup>
										</Whisper>
									</FlexboxGrid.Item>
								</FlexboxGrid>
							</List.Item>
						)
					})}
				</List>
			</Panel>
			<SelectedProducts
				addHandle={addProductsHandle}
				open={open}
				setOpen={setOpen}
				products={selected}
				removedHandle={(value: number) => removedHandle(value)}
			/>
		</>
	) : (
		<EmptyComponent />
	)
}
