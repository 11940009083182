import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {
	ButtonGroup,
	Col,
	Grid,
	List,
	Message,
	Panel,
	Row,
	Stack,
	Text,
	toaster,
	Tooltip,
	Whisper,
} from 'rsuite'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { Heading } from '../../../components/heading/Heading'
import { EmptyComponent } from '../../../components/loader/empty/Empty'
import { LoaderComponent } from '../../../components/loader/Loader'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useDialog } from '../../../hooks/useDialog'
import { IRemoveProductDTO } from '../../../services/promotion/dto'
import { PromotionService } from '../../../services/promotion/Promotion.service'
import { formatDateString } from '../../../utils/formate-date'

export const PromotionPage = () => {
	const { id } = useParams<{ id: string }>()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { openDialog } = useDialog()
	const { data, isLoading } = useQuery({
		queryKey: ['fetchPromoById'],
		queryFn: () => PromotionService.fetchById(Number(id)),
	})

	const { mutateAsync, isLoading: loadingDeleted } = useMutation({
		mutationKey: ['removeProductPromo'],
		mutationFn: (dto: IRemoveProductDTO) => PromotionService.removeProduct(dto),
	})
	const deleteProduct = async (data: IRemoveProductDTO) => {
		openDialog({
			onConfirm: async () => {
				try {
					await mutateAsync(data, {
						onSuccess(data, variables, context) {
							toaster.push(
								<Message showIcon type="success">
									{data.message}
								</Message>
							)
							queryClient.invalidateQueries(['fetchPromoById'])
						},
					})
				} catch (error) {
					toaster.push(
						<Message showIcon type="error">
							{String(error)}
						</Message>
					)
				}
			},
		})
	}
	return isLoading || loadingDeleted ? (
		<LoaderComponent />
	) : data ? (
		<Panel
			header={
				<Stack justifyContent="space-between">
					<Heading type="h5">Название - {data?.title}</Heading>
					<ButtonGroup>
						<ButtonActions
							action="update"
							onClick={() =>
								navigate(`/dashboard/promotions/update/${data.id}`)
							}
						/>
					</ButtonGroup>
				</Stack>
			}
		>
			<Grid fluid>
				<Row gutter={20}>
					<Col xs={24}>
						<Text muted>Изображение</Text>
						<img
							style={{ borderRadius: '5px', width: '100%', height: 'auto' }}
							src={imgHostPath(data.image || '')}
							alt={data.title}
						/>
					</Col>
					<Col
						xs={12}
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '12px',
							margin: '20px 0px',
						}}
					>
						<Text muted>Изображение для мобильных устройств</Text>
						<img
							style={{ borderRadius: '5px', width: '100%' }}
							src={imgHostPath(data.imageSm || '')}
							alt={data.title}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={4}>
						<Text muted>Товаров</Text>
						<Text color="cyan">{data.product.length}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Скидка</Text>
						<Text color="cyan">{data.discount ? data.discount : 'Н/Д'}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Дата создания</Text>
						<Text color="yellow">{formatDateString(data.createdAt)}</Text>
					</Col>
				</Row>
				<Row gutter={20} style={{ margin: '20px 0' }}>
					<Col xs={6}>
						<Text muted>Название</Text>
						<Text color="cyan">{data.title}</Text>
					</Col>

					<Col xs={6}>
						<Text muted>Короткое описание</Text>
						<Text color="cyan">{data.subtitle}</Text>
					</Col>
					<Col xs={6}>
						<Text muted>описание</Text>
						<Text color="cyan">{data.description}</Text>
					</Col>
				</Row>
				<Row gutter={20} style={{ marginTop: '2rem' }}>
					<Stack justifyContent="space-between">
						<Text size={'lg'} color="yellow">
							Товары
						</Text>
						<Whisper
							trigger={'hover'}
							placement="left"
							speaker={
								<Tooltip visible>Здесь можно добавить товары для акции</Tooltip>
							}
						>
							<ButtonGroup>
								<ButtonActions
									title="Добавить товары"
									action="create"
									onClick={() =>
										navigate(`/dashboard/promotions/add-product/${data.id}`)
									}
								/>
							</ButtonGroup>
						</Whisper>
					</Stack>
					{data.product.map((product) => (
						<Col xs={12} key={product.id} style={{ marginTop: '1.5rem' }}>
							<List bordered>
								<List.Item>
									<Stack justifyContent="space-between">
										<Stack.Item>
											<Text muted size={'sm'}>
												Товар
											</Text>
											<Text color="cyan">{product.title}</Text>
										</Stack.Item>
										<Stack.Item>
											<ButtonGroup>
												<ButtonActions
													title="Удалить из акции"
													action="delete"
													onClick={() =>
														deleteProduct({
															id: product.promotionId,
															productId: product.id,
														})
													}
												/>
											</ButtonGroup>
										</Stack.Item>
									</Stack>
								</List.Item>
							</List>
						</Col>
					))}
				</Row>
				<Row style={{ margin: '50px 0px' }}>
					<Text size={'sm'} color="yellow" style={{ margin: '15px 0' }}>
						{!data.active
							? 'Сейчас эта акция не видна и не актуальна. Чтобы он был активным, добавьте товары'
							: 'Новые возможности выйдут последующим обновлением приложения'}
					</Text>
				</Row>
			</Grid>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
