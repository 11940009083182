import { FC } from 'react'
import { MdCreate, MdDelete, MdUpdate } from 'react-icons/md'
import { IconButton } from 'rsuite'
import { IButtonActionsProps } from './ButtonActions.props'

export const ButtonActions: FC<IButtonActionsProps> = ({
	action,
	color = 'red',
	appearance = 'subtle',
	size = 'lg',
	...props
}) => {
	switch (action) {
		case 'create':
			return (
				<IconButton
					size="lg"
					{...props}
					appearance="subtle"
					color="violet"
					icon={<MdCreate />}
				></IconButton>
			)

		case 'update':
			return (
				<IconButton
					size="lg"
					{...props}
					appearance="subtle"
					color="cyan"
					icon={<MdUpdate />}
				></IconButton>
			)

		case 'delete':
			return (
				<IconButton
					size={size}
					{...props}
					appearance={appearance}
					color={color}
					icon={<MdDelete />}
				></IconButton>
			)

		default:
			return (
				<IconButton
					size="lg"
					{...props}
					appearance="subtle"
					color="violet"
					icon={<MdCreate />}
				></IconButton>
			)
	}
}
