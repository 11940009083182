import { FC } from 'react'
import { Form, Input } from 'rsuite'
import { IFieldProps } from './Field.props'

export const Field: FC<IFieldProps> = ({ field, error, ...rest }) => {
	return (
		<>
			<Input
				style={{ margin: '12px 0' }}
				id={field.name}
				value={field.value}
				onChange={(value: string) => field.onChange(value)}
				{...rest}
			/>
			<Form.ErrorMessage show={!!error} placement="bottomStart">
				{error}
			</Form.ErrorMessage>
		</>
	)
}
