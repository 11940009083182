import { useQuery } from '@tanstack/react-query'
import { MdCreate } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
	Button,
	ButtonGroup,
	FlexboxGrid,
	List,
	Panel,
	Stack,
	Text,
} from 'rsuite'
import { ButtonActions } from '../../components/buttonActions/ButtonActions'
import { Heading } from '../../components/heading/Heading'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { LoaderComponent } from '../../components/loader/Loader'
import { imgHostPath } from '../../helpers/imgHostPath'
import { PromotionService } from '../../services/promotion/Promotion.service'

export const PromotionsPage = () => {
	const navigate = useNavigate()

	const { data, isLoading } = useQuery({
		queryKey: ['fetchAllPromotions'],
		queryFn: () => PromotionService.fetchAll(),
	})

	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<Panel
			header={
				<Stack justifyContent="space-between">
					<Heading type="h5">
						Акции
						<Text muted size={'sm'} weight="light">
							всего - {data.length}
						</Text>
					</Heading>
					<ButtonGroup>
						<Button
							size="md"
							appearance="primary"
							color="violet"
							startIcon={<MdCreate />}
							onClick={() => navigate(`/dashboard/promotions/create`)}
						>
							Добавить акцию
						</Button>
					</ButtonGroup>
				</Stack>
			}
		>
			<List hover bordered>
				{data.map((item, index) => (
					<List.Item key={item.id} index={index + 1}>
						<FlexboxGrid align="middle">
							<FlexboxGrid.Item colspan={8}>
								<img
									style={{ objectFit: 'cover', borderRadius: '5px' }}
									width={300}
									height={70}
									src={imgHostPath(item.image)}
									alt="icon"
								/>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={6}>
								<Text muted size={'sm'}>
									Название
								</Text>
								<Text>{item.title}</Text>
							</FlexboxGrid.Item>

							<FlexboxGrid.Item colspan={4}>
								<Text
									color={item.active ? 'green' : 'yellow'}
									size={'sm'}
									title={
										item.active ? 'Активен' : 'Для активации добавьте товары'
									}
								>
									{item.active ? 'Активен' : 'Неактивен'}
								</Text>
							</FlexboxGrid.Item>
							<FlexboxGrid.Item colspan={4}>
								<ButtonGroup>
									<Button
										appearance="link"
										onClick={() =>
											navigate(`/dashboard/promotions/promo/${item.id}`)
										}
									>
										Подробнее
									</Button>
									<ButtonActions
										action="update"
										onClick={() =>
											navigate(`/dashboard/promotions/update/${item.id}`)
										}
									/>
								</ButtonGroup>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</List.Item>
				))}
			</List>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
