import style from './forrbbidden.module.scss'

const ForbbiddenPage = () => {
	return (
		<div className={style.forrbbidden}>
			Forbbidden! This page for only Admins
		</div>
	)
}

export default ForbbiddenPage
