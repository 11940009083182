import { FC, PropsWithChildren, ReactNode, useState } from 'react'
import { ModalContext } from '../context/Modal-context'

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isActive, setIsActive] = useState<boolean>(false)
	const [id, setId] = useState<number | null>(null)
	const [element, setElement] = useState<ReactNode | null>(null)

	const setChaildHandler = (element: ReactNode, id?: number) => {
		setElement(element)
		setIsActive(true)
		if (typeof id !== 'undefined') setId(id)
	}
	const closeHandler = () => {
		setElement(null)
		setIsActive(false)
		setId(null)
	}
	return (
		<ModalContext.Provider
			value={{
				isActive,
				setChaildHandler,
				element,
				id,
				closeHandler,
			}}
		>
			{children}
		</ModalContext.Provider>
	)
}

export default ModalProvider
