import { FC, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { UserRoleEnum } from '../enum/User.enum'
import { useUser } from '../hooks/user/useUser'

interface IProtectedRouteProps {
	isPublic?: boolean
}
export const ProtectedRoute: FC<PropsWithChildren<IProtectedRouteProps>> = ({
	children,
	isPublic,
}) => {
	const { user } = useUser()

	if (isPublic) return children
	else if (
		(!isPublic && user?.role.includes(UserRoleEnum.OWNER)) ||
		user?.role.includes(UserRoleEnum.ADMIN)
	)
		return children
	return <Navigate to={'/'} />
}
