import { useQueryClient } from '@tanstack/react-query'
import { filesize } from 'filesize'
import { FC } from 'react'
import { Button } from 'rsuite'
import { useDialog } from '../../../hooks/useDialog'
import { useGetRole } from '../../../hooks/user/useGetRole'
import { useUser } from '../../../hooks/user/useUser'
import { IProduct } from '../../../interfaces/product.interface'
import { isUsedPosterOrImages } from '../../../utils/isUsedImagesOrPoster'
import style from './cards-image.module.scss'

interface ICardsImageComponentProps {
	products: IProduct[]
	paths: string[]
	totalSize: number
}
export const CardsImageComponent: FC<ICardsImageComponentProps> = ({
	products,
	paths,
	totalSize,
}) => {
	const queryClient = useQueryClient()
	const { users } = useUser()
	const disable = useGetRole()

	const { openDialog } = useDialog()
	// const { isLoading, mutateAsync } = useDeleteFile()

	const deleteHandler = (path: string) => {
		openDialog({
			onConfirm: async () => {
				try {
					// await mutateAsync(path, {
					// 	onSuccess(data, variables, context) {
					// 		// toast.success(data.message)
					// 		queryClient.invalidateQueries(['findAllFiles'])
					// 	},
					// })
				} catch (error) {
					// toast.error(String(error))
				}
			},
		})
	}

	return (
		<div className={style.wrap}>
			<div className={style.storage}>
				<span>
					Общий размер: <b>{filesize(totalSize)}</b>
				</span>
				<span>
					Всего файлов: <b>{paths.length}</b>
				</span>
			</div>
			<div className={style.cards}>
				{paths.map((path, i) => (
					<div className={style.card} key={i}>
						{products.map((product, key) => {
							const usage = isUsedPosterOrImages(product, null, path)
							return usage?.length ? (
								<span className={style.exist} key={key}>
									{usage}
								</span>
							) : null
						})}
						{users?.map((user, key) => {
							const usage = isUsedPosterOrImages(null, user, path)
							return usage?.length ? (
								<span className={style.exist} key={key}>
									{usage}
								</span>
							) : null
						})}

						<img
							src={`${process.env.REACT_APP_API_STATIC}${path}`}
							alt={`card${i}`}
						/>
						<Button
							onClick={() => deleteHandler(path)}
							// disabled={isLoading || !disable}
							className={style.delete}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
