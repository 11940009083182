// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 319.99px) {
  .header_header__MhatS .header_burgerButton__r7sA8 {
    display: flex;
  }
}
@media (min-width: 885.99px) {
  .header_header__MhatS .header_burgerButton__r7sA8 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/_media.mixin.scss","webpack://./src/components/header/header.module.scss"],"names":[],"mappings":"AAGC;ECCA;IAEE,aAAA;EAHD;AACF;AD+CC;EC/CA;IAKE,aAAA;EADD;AACF","sourcesContent":["@import './vars';\n\n@mixin mediaMin320px {\n\t@media (min-width: $media320px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin360px {\n\t@media (min-width: $media360px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin380px {\n\t@media (min-width: $media380px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin440px {\n\t@media (min-width: $media440px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin480px {\n\t@media (min-width: $media480px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin530px {\n\t@media (min-width: $media530px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin640px {\n\t@media (min-width: $media640px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin776px {\n\t@media (min-width: $media776px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin886px {\n\t@media (min-width: $media886px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin960px {\n\t@media (min-width: $media960px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin1024px {\n\t@media (min-width: $media1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin1100px {\n\t@media (min-width: $media1100px) {\n\t\t@content;\n\t}\n}\n\n@mixin mediaMin1280px {\n\t@media (min-width: $media1280px) {\n\t\t@content;\n\t}\n}\n","@import '../../styles/vars';\n@import '../../styles/media.mixin';\n\n.header {\n\t.burgerButton {\n\t\t@include mediaMin320px {\n\t\t\tdisplay: flex;\n\t\t}\n\t\t@include mediaMin886px {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__MhatS`,
	"burgerButton": `header_burgerButton__r7sA8`
};
export default ___CSS_LOADER_EXPORT___;
