import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	ButtonGroup,
	Grid,
	List,
	Panel,
	Rate,
	Row,
	Stack,
	Text,
	Tooltip,
	Whisper,
} from 'rsuite'
import { ButtonActions } from '../../../../components/buttonActions/ButtonActions'
import { Heading } from '../../../../components/heading/Heading'
import { imgHostPath } from '../../../../helpers/imgHostPath'
import { Specification } from '../[specification]/Specification'
import style from '../product.module.scss'
import { IVariantProps } from './Variant.props'

export const Variant: FC<IVariantProps> = ({ data, productId }) => {
	const navigate = useNavigate()
	return (
		<Panel
			header={
				<Stack justifyContent="space-between">
					<Heading
						type="h5"
						style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
					>
						Варианты
						<Text size="sm" color="yellow" weight="thin">
							[Всего: {data?.length}]
						</Text>
					</Heading>
					<Whisper
						trigger={'hover'}
						placement="left"
						speaker={<Tooltip visible>Здесь можно добавить вариант</Tooltip>}
					>
						<ButtonGroup>
							<ButtonActions
								action="create"
								onClick={() =>
									navigate(`/dashboard/products/variant/create/${productId}`)
								}
							/>
						</ButtonGroup>
					</Whisper>
				</Stack>
			}
		>
			{data && (
				<Grid>
					{data.map((variant) => (
						<Row key={variant.id} style={{ margin: '15px 0px' }}>
							<List bordered>
								<List.Item className={style.headItem}>
									<Text color="yellow" size="lg" weight="semibold">
										Вариант {variant.id}
									</Text>
									<Whisper
										trigger={'hover'}
										placement="left"
										speaker={
											<Tooltip visible>Здесь можно обновить вариант</Tooltip>
										}
									>
										<ButtonGroup>
											<ButtonActions
												action="update"
												onClick={() =>
													navigate(
														`/dashboard/products/variant/update/${variant.id}`
													)
												}
											/>
										</ButtonGroup>
									</Whisper>
								</List.Item>
							</List>
							<List bordered className={style.list}>
								<List.Item className={style.someItem}>
									<Text muted>Артикул</Text>
									<Text>{variant?.articleNumber}</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>Цена</Text>
									<Text>{variant?.price}KGS</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>На складе</Text>
									<Text color={variant.stock < 1 ? 'red' : 'green'}>
										{variant.stock}
									</Text>
								</List.Item>
								{variant.size ? (
									<List.Item className={style.someItem}>
										<Text muted>Размер</Text>
										<Text>{variant?.size}</Text>
									</List.Item>
								) : null}

								<List.Item className={style.someItem}>
									<Text muted>Видео</Text>
									<Text>Недоступно</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>Новое</Text>
									<Text>{variant?.isNew ? 'да' : 'нет'}</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>Хит</Text>
									<Text>{variant?.isHit ? 'да' : 'нет'}</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>Рейтинг</Text>
									<Text>
										<Rate size="xs" defaultValue={variant.rating} />
									</Text>
								</List.Item>
								<List.Item className={style.someItem}>
									<Text muted>Скидка</Text>
									<Text>{variant.discount}</Text>
								</List.Item>
							</List>
							<List bordered>
								<List.Item className={style.headItem}>
									<Text color="yellow" size="lg" weight="semibold">
										Цвет
									</Text>
									<Whisper
										trigger={'hover'}
										placement="left"
										speaker={
											<Tooltip visible>Здесь можно обновить цвет</Tooltip>
										}
									>
										<ButtonGroup>
											<ButtonActions
												action="update"
												onClick={() =>
													navigate(
														`/dashboard/products/color/update/${variant.color.id}`
													)
												}
											/>
										</ButtonGroup>
									</Whisper>
								</List.Item>
								<List.Item>
									<Text>{variant?.color.color}</Text>
								</List.Item>
								<List.Item
									style={{
										display: 'flex',
										gap: '12px',
										alignItems: 'center',
									}}
								>
									{variant.color.images.map((img, index) => (
										<img
											key={index}
											src={imgHostPath(img)}
											alt={img}
											width={50}
											height={70}
											style={{ borderRadius: '5px' }}
										/>
									))}
								</List.Item>
							</List>
							{/* SPECIFICATIONS */}
							<Specification variantId={variant.id} data={variant} />
						</Row>
					))}
				</Grid>
			)}
		</Panel>
	)
}
