import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CategoryService } from '../../services/category/Category.service'

export const useFetchCategoriesBySlug = (slug?: string) => {
	const navigate = useNavigate()
	const data = useQuery({
		queryKey: ['fetchBySlug'],
		queryFn: () => CategoryService.fetchBySlug(slug),
	})

	useEffect(() => {
		if (data.isError) {
			navigate('/404')
		}
		// eslint-disable-next-line
	}, [data.isError])

	return data
}
