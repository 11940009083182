import { AiOutlineDashboard } from 'react-icons/ai'
import { BiBorderAll, BiCategory, BiImage } from 'react-icons/bi'
import { CiDiscount1 } from 'react-icons/ci'
import { FiUsers } from 'react-icons/fi'
import { IoBagOutline } from 'react-icons/io5'
import { IMenu } from '../../interfaces/menu.interface'

export const MENU_DATA: IMenu[] = [
	{
		to: '/dashboard',
		Icon: <AiOutlineDashboard />,
		name: 'Главная',
	},
	{
		to: '/dashboard/users',
		Icon: <FiUsers />,
		name: 'Пользователи',
	},
	{
		to: '/dashboard/products',
		Icon: <IoBagOutline />,
		name: 'Товары',
	},
	{
		to: '/dashboard/categories',
		Icon: <BiCategory />,
		name: 'Категории',
	},
	{
		to: '/dashboard/orders',
		Icon: <BiBorderAll />,
		name: 'Заказы',
	},
	{
		to: '/dashboard/promotions',
		Icon: <CiDiscount1 />,
		name: 'Акции',
	},
	{
		to: '/dashboard/explorer',
		Icon: <BiImage />,
		name: 'Проводник файлов',
	},
]
