import { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ButtonGroup, Col, Grid, List, Panel, Row, Stack, Text } from 'rsuite'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { Heading } from '../../../components/heading/Heading'
import { LoaderComponent } from '../../../components/loader/Loader'
import { EmptyComponent } from '../../../components/loader/empty/Empty'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useDeleteCategory } from '../../../hooks/categories/useDeleteCategory'
import { useFetchCategoriesBySlug } from '../../../hooks/categories/useFindCategoryByAlias'

export const Category: FC = () => {
	const navigate = useNavigate()
	const { slug } = useParams<{ slug: string }>()
	// const { data } = useFindMainCategoryBySlug(slug)

	const { data, isLoading } = useFetchCategoriesBySlug(slug)

	const deleteHandle = useDeleteCategory('fetchBySlug')

	console.log(data)
	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<Panel
			header={
				<Stack justifyContent="space-between">
					<Heading type="h5">Категория - {data.name}</Heading>
					<ButtonGroup>
						<ButtonActions
							title="Добавить категорию"
							action="create"
							onClick={() =>
								navigate(`/dashboard/categories/child/create/${data.id}`)
							}
						/>
					</ButtonGroup>
				</Stack>
			}
		>
			<Grid fluid>
				<Row>
					<Col
						xs={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Text muted>Картинка</Text>
						<img
							style={{ borderRadius: '5px' }}
							src={imgHostPath(data.image || '')}
							width={70}
							height={100}
							alt={data.name}
						/>
					</Col>
					<Col
						xs={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Text muted>Иконка</Text>
						<img
							style={{ borderRadius: '8px', backgroundColor: '#fff' }}
							src={imgHostPath(data.icon || '')}
							width={50}
							height={50}
							alt={data.name}
						/>
					</Col>
					<Col xs={6}>
						<Text muted>Название</Text>
						<Text color="cyan">{data.name}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Товаров</Text>
						<Text color="cyan">{data.products.length}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Подкатегорий</Text>
						<Text color="cyan">{data.childs.length}</Text>
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Text size={'lg'} color="yellow">
						Подкатегории
					</Text>
					{data.childs.map((child) => (
						<Col xs={12} key={child.id} style={{ marginTop: '1.5rem' }}>
							<List bordered>
								<List.Item>
									<Stack justifyContent="space-between">
										<Link to={`/dashboard/categories/child/${child.slug}`}>
											<Text color="cyan">{child.name}</Text>
										</Link>
										<ButtonGroup>
											<ButtonActions
												action="update"
												onClick={() =>
													navigate(
														`/dashboard/categories/child/update/${child.id}`
													)
												}
											/>
											<ButtonActions
												action="delete"
												onClick={() => deleteHandle(child.id)}
											/>
										</ButtonGroup>
									</Stack>
								</List.Item>
							</List>
						</Col>
					))}
				</Row>
			</Grid>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
