import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Heading } from '../../components/heading/Heading'
import { LoaderComponent } from '../../components/loader/Loader'
import { Pagination } from '../../components/pagination/Pagination'
import { useStoreActions } from '../../hooks/useStoreActions'
import { useWebSocket } from '../../hooks/ws/useWebsocket'
import { IUser } from '../../interfaces/user.interface'
import { ISocketResponse } from '../../providers/Ws.Provider'
import { userSerivce } from '../../services/user/user.service'
import style from './users.module.scss'
import UsersTable from './UsersTable'

const UsersPage = () => {
	const { addUsers } = useStoreActions()
	const queryClient = useQueryClient()
	const [users, setUsers] = useState<IUser[] | undefined>([])

	// const disable = useGetRole()
	const socket = useWebSocket()

	// FETCH ALL USERS
	const { data, isLoading, isSuccess } = useQuery(
		['findAllUsers'],
		() => userSerivce.findAllUsers(),
		{
			cacheTime: 0,
		}
	)

	useEffect(() => {
		if (data) {
			setUsers(data)
			addUsers(data)
		}
		// eslint-disable-next-line
	}, [isSuccess, data])

	useEffect(() => {
		const handleOnlineStatus = (userData: ISocketResponse[]) => {
			console.log(userData)
			queryClient.invalidateQueries(['findAllUsers'])
		}
		if (socket) {
			socket.on('online', handleOnlineStatus)
		}
		// eslint-disable-next-line
	}, [socket])

	return isLoading ? (
		<LoaderComponent />
	) : users ? (
		<>
			<Heading type="h4" children={'Пользователи'} className="section-title" />
			<div className={style.users}>
				<UsersTable isLoading={isLoading} users={users} />
				<Pagination data={users} />
			</div>
		</>
	) : null
	// return (
	// 	<div className={style.users}>
	// 		<div className={style.top}>
	// 			<span className="section-title">Пользователи</span>
	// 			{/* ADD PRODUCT */}
	// 			<NewButtonsComponent
	// 				disabled={!disable}
	// 				btnType="add"
	// 				onClick={() => setChaildHandler(<RegisterUser />)}
	// 			/>
	// 		</div>
	// 		<TableComponent users={users || []} />
	// 	</div>
	// )
}

export default UsersPage
