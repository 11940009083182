import cn from 'classnames'
import { FC } from 'react'
import { Button } from 'rsuite'
import { useDialog } from '../../hooks/useDialog'
import style from './dialog.module.scss'

export const DialogComponent: FC = () => {
	const { closeDialog, dialogContent, isOpen } = useDialog()
	return (
		<div className={cn(style.wrap, { [style.isOpen]: isOpen })}>
			<div className={style.dialog}>
				<p>Вы уверены, что хотите удалить?</p>
				<div className={style.btns}>
					<Button
						onClick={() => {
							dialogContent?.onConfirm && dialogContent.onConfirm()
							closeDialog()
						}}
					>
						Да
					</Button>
					<Button
						onClick={() => {
							dialogContent?.onCancel && dialogContent.onCancel()
							closeDialog()
						}}
					>
						Нет
					</Button>
				</div>
			</div>
		</div>
	)
}
