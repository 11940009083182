import apiBase from '../../api/AxiosBase'
import { ICategory } from '../../interfaces/category.interface'
import { ICategoryDTO, ICreateChildDTO, IUpdateChildDTO } from './dto'

const CATEGORY_PATH: string = '/category'
export const CategoryService = {
	/**
	 *
	 * @param data
	 * @returns
	 */
	async create(data: ICategoryDTO) {
		const response = await apiBase<ICategory>({
			url: `${CATEGORY_PATH}`,
			method: 'POST',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param data
	 * @returns
	 */
	async createChild(data: ICreateChildDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${CATEGORY_PATH}/child`,
			method: 'POST',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param data IUpdateChildDTO
	 * @returns
	 */
	async updateChild(data: IUpdateChildDTO) {
		const response = await apiBase<{ message: string }>({
			url: `${CATEGORY_PATH}/${data.id}`,
			method: 'PATCH',
			data,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param data
	 * @returns
	 */
	async update(data: ICategoryDTO, id?: number) {
		if (id) {
			const response = await apiBase<{ message: string }>({
				url: `${CATEGORY_PATH}/${id}`,
				method: 'PATCH',
				data,
			})
			return response.data
		}
		return null
	},
	/**
	 * @description FIND ALL PARENTS
	 * @returns
	 */
	async fetchParents() {
		const response = await apiBase<ICategory[]>({
			url: `${CATEGORY_PATH}/parents`,
			method: 'GET',
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchByParentId(id: number) {
		const response = await apiBase<ICategory[]>({
			url: `${CATEGORY_PATH}/by-parent/${id}`,
			method: 'GET',
		})
		return response.data
	},

	/**
	 *
	 * @param slug
	 * @returns
	 */
	async fetchBySlug(slug?: string) {
		if (slug) {
			const response = await apiBase<ICategory>({
				url: `${CATEGORY_PATH}/by-slug/${slug}`,
				method: 'GET',
			})
			return response.data
		}
		return undefined
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async fetchById(id?: number) {
		if (id) {
			const response = await apiBase<ICategory>({
				url: `${CATEGORY_PATH}/${id}`,
				method: 'GET',
			})
			return response.data
		}
		return undefined
	},

	/**
	 *
	 * @param id
	 * @returns
	 */
	async delete(id: number) {
		const response = await apiBase<{ message: string }>({
			url: `${CATEGORY_PATH}/${id}`,
			method: 'DELETE',
		})
		return response.data
	},
}
