// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_panel__LhQJm {
  background: rgba(67, 65, 67, 0.24);
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.scss","webpack://./src/styles/_vars.scss"],"names":[],"mappings":"AACA;EACC,kCCCQ;ADDT","sourcesContent":["@import '../../styles/vars';\n.panel {\n\tbackground: $darken;\n}\n","$mainBlack: #202020;\n$mainWhite: #fff;\n$rgbaGray: rgba(194, 194, 194, 0.24);\n$darken: rgb(67 65 67 / 24%);\n$grayDark: #868686;\n$gray: #d6d6d6;\n$skay: #1771c5;\n$purple: #db1790;\n$primary: #8e2cdf;\n$primarySecond: #ca54daf8;\n\n// BREAKPOINTS\n$media320px: 319.99px;\n$media360px: 359.99px;\n$media380px: 379.99px;\n$media440px: 439.99px;\n$media480px: 479.99px;\n$media530px: 529.99px;\n$media640px: 639.99px;\n$media776px: 775.99px;\n$media886px: 885.99px;\n$media960px: 959.99px;\n$media1024px: 1023.99px;\n$media1100px: 1099.99px;\n$media1280px: 1279.99px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `home_panel__LhQJm`
};
export default ___CSS_LOADER_EXPORT___;
