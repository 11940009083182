import { FC } from 'react'
import { MdList } from 'react-icons/md'
import { Button, FlexboxGrid, Header as HeaderCMP } from 'rsuite'
import { useFetchUserProfile } from '../../hooks/user/useFetchUserProfile'
import User from '../user/User'
import { IHeaderProps } from './Header.props'
import style from './header.module.scss'

const Header: FC<IHeaderProps> = ({ collapsed, setCollapsed }) => {
	const user = useFetchUserProfile()

	return (
		<HeaderCMP className={style.header} style={{ padding: '15px 10px' }}>
			<FlexboxGrid>
				<FlexboxGrid.Item
					colspan={12}
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '12px',
						paddingRight: '15px',
					}}
				>
					<Button
						appearance="link"
						className={style.burgerButton}
						startIcon={<MdList />}
						onClick={() =>
							setCollapsed ? setCollapsed(!collapsed) : undefined
						}
					/>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item
					style={{
						justifyContent: 'flex-end',
						display: 'flex',
						paddingRight: '15px',
					}}
					colspan={12}
				>
					<User user={user} />
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</HeaderCMP>
	)
}

export default Header
