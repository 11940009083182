import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonGroup, Col, Grid, List, Panel, Row, Stack, Text } from 'rsuite'
// import { CreateChild } from '../../../components/form/categories/CreateChild'
// import { UpdateChild } from '../../../components/form/categories/UpdateChild'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { Heading } from '../../../components/heading/Heading'
import { EmptyComponent } from '../../../components/loader/empty/Empty'
import { LoaderComponent } from '../../../components/loader/Loader'
import { useDeleteCategory } from '../../../hooks/categories/useDeleteCategory'
import { useFetchCategoriesBySlug } from '../../../hooks/categories/useFindCategoryByAlias'
import { useModal } from '../../../hooks/useModal'

export const ChildsCategory: FC = () => {
	const { slug } = useParams<{ slug: string }>()
	const navigate = useNavigate()
	const { data, isLoading } = useFetchCategoriesBySlug(slug)
	const { setChaildHandler } = useModal()

	const deleteHandle = useDeleteCategory('fetchBySlug')

	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<Panel
			header={
				<Stack justifyContent="space-between">
					<Heading type="h5">Категория - {data.name}</Heading>
					<ButtonGroup>
						<ButtonActions
							title="Добавить категорию"
							action="create"
							onClick={() =>
								navigate(`/dashboard/categories/child/create/${data.id}`)
							}
						/>
					</ButtonGroup>
				</Stack>
			}
		>
			<Grid fluid>
				<Row>
					<Col xs={6}>
						<Text muted>Название</Text>
						<Text color="cyan">{data.name}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Товаров</Text>
						<Text color="cyan">{data.products.length}</Text>
					</Col>
					<Col xs={4}>
						<Text muted>Подкатегорий</Text>
						<Text color="cyan">{data.childs.length}</Text>
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Text size={'lg'} color="yellow">
						Подкатегории
					</Text>
					{data.childs.map((child) => (
						<Col xs={12} key={child.id} style={{ marginTop: '1.5rem' }}>
							<List bordered>
								<List.Item>
									<Stack justifyContent="space-between">
										<Text color="cyan">{child.name}</Text>
										<ButtonGroup>
											<ButtonActions
												action="update"
												onClick={() =>
													navigate(
														`/dashboard/categories/child/update/${child.id}`
													)
												}
											/>
											<ButtonActions
												action="delete"
												onClick={() => deleteHandle(child.id)}
											/>
										</ButtonGroup>
									</Stack>
								</List.Item>
							</List>
						</Col>
					))}
				</Row>
			</Grid>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
