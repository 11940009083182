import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Form,
	Message,
	Panel,
	Placeholder,
	Text,
	toaster,
	Uploader,
} from 'rsuite'
import { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../../api/AxiosBase'
import { ButtonActions } from '../../../components/buttonActions/ButtonActions'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { LoaderComponent } from '../../../components/loader/Loader'
import { imgHostPath } from '../../../helpers/imgHostPath'
import { useDialog } from '../../../hooks/useDialog'
import { PromotionService } from '../../../services/promotion/Promotion.service'
import { ICreatePromoDTO } from '../../../services/promotion/dto'

export const UpdatePromoPage = () => {
	const { id } = useParams<{ id: string }>()
	const [image, setImage] = useState<any>()
	const [imageSm, setImageSm] = useState<any>()
	const { openDialog } = useDialog()
	const [files, setFiles] = useState<FileType[]>([])
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		queryKey: ['fetchPromoById'],
		queryFn: () => PromotionService.fetchById(Number(id)),
	})

	const { mutateAsync: deleteFile, isLoading: deleteLoading } = useMutation({
		mutationKey: ['deletePromoImage'],
		mutationFn: (path: string) =>
			PromotionService.deleteImage({ id: Number(id), path }),
	})
	const navigate = useNavigate()

	const { mutateAsync, isLoading: updateLoading } = useMutation({
		mutationKey: ['createPromo'],
		mutationFn: (data: ICreatePromoDTO) =>
			PromotionService.update(Number(id), data),
	})

	const {
		handleSubmit,
		control,
		setValue,
		formState: { isValid },
	} = useForm<ICreatePromoDTO>({
		mode: 'onChange',
		defaultValues: {
			title: '',
			description: '',
			subtitle: '',
		},
	})

	useEffect(() => {
		if (data) {
			setValue('title', data.title)
			setValue('description', data.description)
			setValue('subtitle', data.subtitle)
		}
	}, [data])
	const updateHandle = async (values: Omit<ICreatePromoDTO, 'image'>) => {
		try {
			if (!image) {
				toaster.push(
					<Message type="info" showIcon>
						Выберите картинку
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...values,
					image: image.filePath || data?.image,
					imageSm: imageSm.filePath || data?.imageSm,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{String(error)}
				</Message>
			)
		}
	}

	const deleteFileHandle = (path: string) => {
		openDialog({
			onConfirm: async () => {
				deleteFile(path)
				queryClient.invalidateQueries(['fetchPromoById'])
			},
		})
	}

	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<Panel header="Обновить акцию" style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(updateHandle)}
			>
				<Form.Group>
					<Form.ControlLabel>Название</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="title"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.ControlLabel>Подзаголовок</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="subtitle"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.ControlLabel>Описание</Form.ControlLabel>
					<Controller<ICreatePromoDTO>
						name="description"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								as={'textarea'}
								height={200}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
						margin: '30px 0px',
					}}
				>
					<div style={{ overflow: 'hidden' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Text size={'md'}>Текущее изображение</Text>
							<ButtonActions
								action="delete"
								onClick={() => deleteFileHandle(data.image)}
							/>
						</div>
						<div
							style={{
								width: '100%',
								height: '168px',
								overflow: 'hidden',
								borderRadius: '20px',
							}}
						>
							{deleteLoading ? (
								<LoaderComponent />
							) : !data.imageSm ? (
								<Placeholder.Graph active />
							) : (
								<img
									style={{ width: '100%', height: '100%', objectFit: 'cover' }}
									src={imgHostPath(data.image)}
									alt={data.title}
								/>
							)}
						</div>
					</div>
					<div style={{ overflow: 'hidden' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Text size={'md'}>
								Текущее изображение для мобильных устройств
							</Text>
							<ButtonActions
								action="delete"
								onClick={() => deleteFileHandle(data.imageSm)}
							/>
						</div>
						<div
							style={{
								width: '100%',
								height: '168px',
								overflow: 'hidden',
								borderRadius: '20px',
							}}
						>
							{deleteLoading ? (
								<LoaderComponent />
							) : !data.imageSm ? (
								<Placeholder.Graph active />
							) : (
								<img
									style={{ objectFit: 'cover', width: '100%', height: '100%' }}
									src={imgHostPath(data.imageSm)}
									alt={data.title}
								/>
							)}
						</div>
					</div>
				</div>
				<Form.Group controlId="uploader">
					<Form.ControlLabel>
						<Text size={'md'} color="yellow">
							Изображение
						</Text>
						<Text size={'md'} color="yellow">
							Должен быть не менее 1400x400
						</Text>
					</Form.ControlLabel>
					<Uploader
						disabled={files.length > 0}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-image`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setImage(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>

				<Form.Group controlId="uploader">
					<Form.ControlLabel>
						<Text size={'md'} color="yellow">
							Изображение для мобильных устройств
						</Text>
						<Text size={'md'} color="yellow">
							Должен быть не менее 990x400
						</Text>
					</Form.ControlLabel>
					<Uploader
						disabled={files.length > 1}
						multiple={false}
						listType="picture"
						name="file"
						withCredentials
						action={`${APP_URI}/upload/category-image`}
						onChange={(file) => setFiles(file)}
						onUpload={async (file, response, xhr) => {
							xhr.onloadend = async () => {
								const data = await JSON.parse(xhr.response)
								setImageSm(data)
								toaster.push(
									<Message type="success" showIcon>
										{data.message}
									</Message>
								)
							}
							xhr.onerror = (error) => {
								toaster.push(
									<Message type="error" showIcon>
										Произошла ошибка при загрузке файла. <br />
										Повторите попытку.
									</Message>
								)
							}
						}}
					/>
				</Form.Group>

				<FormButtons
					disabled={!isValid || (!image && !imageSm)}
					isLoading={isLoading}
					type="update"
				/>
			</Form>
		</Panel>
	) : null
}
