import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonGroup, List, Text, Tooltip, Whisper } from 'rsuite'
import { ButtonActions } from '../../../../components/buttonActions/ButtonActions'
import style from '../product.module.scss'
import { ISpecificationProps } from './Specification.props'

export const Specification: FC<ISpecificationProps> = ({ data, variantId }) => {
	const navigate = useNavigate()

	return !data ? (
		<>Спецификаций нет</>
	) : (
		<List bordered>
			<List.Item className={style.headItem}>
				<Text color="yellow" size="lg" weight="semibold">
					Спецификации
				</Text>
				<ButtonGroup>
					<Whisper
						trigger={'hover'}
						placement="left"
						speaker={
							<Tooltip visible>Здесь можно обновить спецификации</Tooltip>
						}
					>
						<div style={{ display: 'inline-block' }}>
							<ButtonActions
								onClick={() =>
									navigate(
										`/dashboard/products/specification/update/${variantId}`
									)
								}
								action="update"
							/>
						</div>
					</Whisper>
					<Whisper
						trigger={'hover'}
						placement="left"
						speaker={<Tooltip visible>Добавить спецификации</Tooltip>}
					>
						<div style={{ display: 'inline-block' }}>
							<ButtonActions action="create" />
						</div>
					</Whisper>
				</ButtonGroup>
			</List.Item>
			<List.Item className={style.list}>
				{data.specifications.map((specification) => (
					<List.Item key={specification.id} className={style.someItem}>
						<Text muted>{specification.name}</Text>
						<Text>{specification.value}</Text>
					</List.Item>
				))}
			</List.Item>
		</List>
	)
}
