import { AuthEnumName } from '../enum/auth.enum'

export const saveToLocalStorage = (token: string) => {
	localStorage.setItem(AuthEnumName.ACCESS_TOKEN, token)
	localStorage.setItem(AuthEnumName.REFRESH_TOKEN, token)
}
export const getAccessTokenFromStorage = () => {
	const accessToken = localStorage.getItem(AuthEnumName.ACCESS_TOKEN)
	return accessToken
}

export const getRefreshTokenFromStorage = () => {
	const refreshToken = localStorage.getItem(AuthEnumName.REFRESH_TOKEN)
	return refreshToken
}

export const clearTokensOnStorage = () => {
	localStorage.removeItem(AuthEnumName.ACCESS_TOKEN)
	localStorage.removeItem(AuthEnumName.REFRESH_TOKEN)
}
