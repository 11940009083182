import { useMutation } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Divider, Form, Message, Text, toaster, Uploader } from 'rsuite'
import { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../../api/AxiosBase'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { ICreateColorDTO } from '../../../services/product/dto'
import { ProductService } from '../../../services/product/Product.service'

export const CreateColorPage: FC<{ variantId?: number }> = ({ variantId }) => {
	const [uploadResponse, setUploadResponse] = useState<{ images: string[] }>({
		images: [],
	})
	const [files, setFiles] = useState<FileType[]>([])

	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['createColor'],
		mutationFn: (dto: ICreateColorDTO) => ProductService.createColor(dto),
	})

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm<ICreateColorDTO>({
		mode: 'onChange',
	})

	const createHandle = async (values: ICreateColorDTO) => {
		try {
			if (variantId && uploadResponse.images) {
				await mutateAsync(
					{
						...values,
						images: uploadResponse.images,
						variantId,
					},
					{
						onSuccess(data, variables, context) {
							toaster.push(
								<Message showIcon type="success">
									{data.message}
								</Message>
							)
						},
					}
				)
			}
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{error}
				</Message>
			)
		}
	}

	return !variantId ? (
		<Text as="q" color="blue">
			Чтобы добавить цвет,создайте вариант
		</Text>
	) : (
		<Form
			// @ts-ignore
			onSubmit={handleSubmit(createHandle)}
		>
			<Controller
				name="color"
				control={control}
				rules={{ required: 'Введите цвет варианта' }}
				render={({ field, fieldState }) => (
					<Field
						field={field}
						error={errors[field.name]?.message as string}
						placeholder="Цвет"
					/>
				)}
			/>
			<Form.Group controlId="uploader">
				<Form.ControlLabel>Изображения</Form.ControlLabel>
				<Form.Control
					disabled={files.length > 5}
					multiple={false}
					listType="picture"
					name="file"
					accepter={Uploader}
					withCredentials
					action={`${APP_URI}/upload/product-poster`}
					onChange={(file) => setFiles(file)}
					onUpload={async (file, response, xhr) => {
						xhr.onloadend = async () => {
							const data: { filePath: string; message: string } =
								await JSON.parse(xhr.response)
							setUploadResponse((prevState) => ({
								images: [...prevState.images, data.filePath],
							}))
							toaster.push(
								<Message type="success" showIcon>
									{data.message}
								</Message>
							)
						}
						xhr.onerror = (error) => {
							toaster.push(
								<Message type="error" showIcon>
									Произошла ошибка при загрузке файла. <br />
									Повторите попытку.
								</Message>
							)
						}
					}}
				/>
			</Form.Group>
			<Divider />

			<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
		</Form>
	)
}
