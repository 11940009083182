import { createContext } from 'react'

export interface DialogContent {
	message?: string
	onConfirm?: () => void
	onCancel?: () => void
}

interface IDialogContext {
	openDialog: (content: DialogContent) => void
	closeDialog: () => void
	dialogContent: DialogContent | null
	isOpen?: boolean
}
const initialContext: IDialogContext = {
	closeDialog: () => {},
	openDialog: () => {},
	dialogContent: { message: '' },
	isOpen: false,
}
export const DialogContext = createContext(initialContext)
