import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Col, Form, Grid, Message, Row, useToaster } from 'rsuite'
import Uploader, { FileType } from 'rsuite/esm/Uploader'
import { APP_URI } from '../../api/AxiosBase'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { ISelectedCategories } from '../../components/form/product/ProductForm.props'
import { SelectedCategory } from '../../components/form/product/SelectedCategory'
import { Heading } from '../../components/heading/Heading'
import { ProductService } from '../../services/product/Product.service'
import { ICreateProductDTO } from '../../services/product/dto'

const CreateProduct = () => {
	const [uploadResponse, setUploadResponse] = useState<any>()
	const navigate = useNavigate()
	const [categoryIds, setCategoryIds] = useState<ISelectedCategories>({
		child: undefined,
		main: undefined,
		parent: undefined,
	})
	const [files, setFiles] = useState<FileType[]>([])
	const toaster = useToaster()
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<ICreateProductDTO>({
		defaultValues: {
			categoryIds: [],
			description: '',
			poster: '',
			subtitle: '',
			title: '',
		},
	})

	const { mutateAsync, isLoading } = useMutation({
		mutationKey: ['createProduct'],
		mutationFn: (values: ICreateProductDTO) => ProductService.create(values),
	})

	const createHandle = async (values: ICreateProductDTO) => {
		const categories = Object.values(categoryIds).filter(
			(id) => id !== undefined
		)
		try {
			if (!uploadResponse?.filePath.length) {
				toaster.push(
					<Message showIcon type="error">
						Выберите постер
					</Message>
				)
				return
			}

			await mutateAsync(
				{
					...values,
					poster: uploadResponse.filePath,
					categoryIds: categories,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}

	return (
		<Grid fluid>
			<Row gutter={20}>
				<Col xs={12}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Добавить товар"
					/>
					<Form
						// @ts-ignore
						onSubmit={handleSubmit(createHandle)}
					>
						<Controller
							name="title"
							control={control}
							rules={{ required: 'Название обязательно' }}
							render={({ field, fieldState }) => (
								<Field
									field={field}
									error={errors[field.name]?.message as string}
									placeholder="Название"
								/>
							)}
						/>

						<Controller
							name="subtitle"
							control={control}
							rules={{
								required: 'Подзаголовок обязательно',
							}}
							render={({ field, fieldState }) => (
								<Field
									field={field}
									error={errors[field.name]?.message as string}
									placeholder="Подзаголовок"
								/>
							)}
						/>

						<Controller
							name="description"
							control={control}
							rules={{
								required: 'Описание обязательно',
							}}
							render={({ field, fieldState }) => (
								<Field
									height={200}
									as={'textarea'}
									field={field}
									error={errors[field.name]?.message as string}
									placeholder="Описание"
								/>
							)}
						/>

						<Form.Group controlId="uploader">
							<Form.ControlLabel>Постер</Form.ControlLabel>
							<Uploader
								disabled={files.length > 0}
								multiple={false}
								listType="picture"
								name="file"
								withCredentials
								action={`${APP_URI}/upload/product-poster`}
								onChange={(file) => setFiles(file)}
								onUpload={async (file, response, xhr) => {
									xhr.onloadend = async () => {
										const data = await JSON.parse(xhr.response)
										setUploadResponse(data)
										toaster.push(
											<Message type="success" showIcon>
												{data.message}
											</Message>
										)
									}
									xhr.onerror = (error) => {
										toaster.push(
											<Message type="error" showIcon>
												Произошла ошибка при загрузке файла. <br />
												Повторите попытку.
											</Message>
										)
									}
								}}
							/>
						</Form.Group>

						<FormButtons
							disabled={!categoryIds.main}
							isLoading={isLoading}
							type="submit"
						/>
					</Form>
				</Col>

				<Col xs={12}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children="Категории товара"
					/>
					<SelectedCategory
						popOverText={
							<>
								{' '}
								<span>Незабудьте выбрать категорию</span>
								<br />
								<small>
									<q>Если не выбрать категорию, товар не появится на сайте</q>
								</small>
							</>
						}
						categoryIds={categoryIds}
						setCategoryIds={setCategoryIds}
					/>
				</Col>
			</Row>
		</Grid>
	)
}

export default CreateProduct
