import { createBrowserRouter } from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import RootLayout from '../layouts/RootLayout'
import NotFound from '../pages/404/404'
import ForbbiddenPage from '../pages/404/Forbbidden'
import AuthPage from '../pages/auth/Auth'
import { CategoriesPage } from '../pages/categories/Categories'
import { CreateCategoryPage } from '../pages/categories/CreateCategory'
import { CreateChildPage } from '../pages/categories/CreateChild'
import { UpdateCategoryPage } from '../pages/categories/UpdateCategory'
import { UpdateChildPage } from '../pages/categories/UpdateChild'
import { Category } from '../pages/categories/[category]/[Category]'
import { ChildsCategory } from '../pages/categories/[childs]/[Childs]'
import { ExplorerPage } from '../pages/explorer/Explorer'
import Home from '../pages/home/Home'
import { OrdersPage } from '../pages/orders/Orders'
import { OrderPage } from '../pages/orders/[order]/Order'
import CreateProduct from '../pages/products/CreateProduct'
import ProductsPage from '../pages/products/ProductsPage'
import UpdateProduct from '../pages/products/UpdateProduct'
import { ProductPage } from '../pages/products/[product]/Product'
import { UpdateColorPage } from '../pages/products/color/UpdateColor'
import { UpdateSpecification } from '../pages/products/specifications/UpdateSpecification'
import { CreateVariantPage } from '../pages/products/variant/CreateVariant'
import { UpdateVariantPage } from '../pages/products/variant/UpdateVariant'
import { PromotionsPage } from '../pages/promotion/Promotions'
import { AddProductPage } from '../pages/promotion/[create]/[AddProduct]'
import { CreatePromoPage } from '../pages/promotion/[create]/[CreatePromo]'
import { PromotionPage } from '../pages/promotion/[promotion]/Promotion'
import { UpdatePromoPage } from '../pages/promotion/[update]/[UpdatePromo]'
import UsersPage from '../pages/users/Users'
import { ProtectedRoute } from './ProtectedRoute'

export const router = createBrowserRouter([
	{
		path: '/',
		element: <AuthLayout />,
		children: [
			{
				index: true,
				element: <ProtectedRoute children={<AuthPage />} isPublic />,
			},
			{
				path: '/*',
				element: <NotFound />,
			},
		],
	},
	{
		path: '/dashboard',
		element: <RootLayout />,
		children: [
			{
				index: true,
				element: <ProtectedRoute children={<Home />} isPublic />,
			},
			{
				path: '/dashboard/products',
				element: (
					<ProtectedRoute children={<ProductsPage />} isPublic={false} />
				),
			},
			{
				path: '/dashboard/products/:slug',
				element: <ProductPage />,
			},
			{
				path: '/dashboard/products/create',
				element: (
					<ProtectedRoute children={<CreateProduct />} isPublic={false} />
				),
			},
			{
				path: '/dashboard/products/update/:slug',
				element: (
					<ProtectedRoute children={<UpdateProduct />} isPublic={false} />
				),
			},
			{
				path: '/dashboard/products/variant/create/:id',
				element: (
					<ProtectedRoute children={<CreateVariantPage />} isPublic={false} />
				),
			},
			{
				path: '/dashboard/products/variant/update/:id',
				element: (
					<ProtectedRoute children={<UpdateVariantPage />} isPublic={false} />
				),
			},
			// {
			// 	path: '/dashboard/products/specification/create/:id',
			// 	element: (
			// 		<ProtectedRoute
			// 			children={<CreateSpecificationsPage />}
			// 			isPublic={false}
			// 		/>
			// 	),
			// },
			{
				path: '/dashboard/products/specification/update/:id',
				element: (
					<ProtectedRoute children={<UpdateSpecification />} isPublic={false} />
				),
			},
			// {
			// 	path: '/dashboard/products/color/create/:id',
			// 	element: (
			// 		<ProtectedRoute children={<CreateColorPage />} isPublic={false} />
			// 	),
			// },
			{
				path: '/dashboard/products/color/update/:id',
				element: (
					<ProtectedRoute children={<UpdateColorPage />} isPublic={false} />
				),
			},

			// {
			// 	path: '/dashboard/specification/update/:id',
			// 	element: <UpdateSpecificationPage />,
			// },
			// {
			// 	path: '/dashboard/product/attributes/create/:alias',
			// 	element: <ProductAttributesCreatePage />,
			// },
			// {
			// 	path: `/dashboard/product/attributes/update/:id`,
			// 	element: <ProductAttributeUpdatePage />,
			// },

			/**
			 * ### CATEGORIES
			 */
			{
				path: '/dashboard/categories',
				element: (
					<ProtectedRoute children={<CategoriesPage />} isPublic={false} />
				),
			},
			{
				path: '/dashboard/categories/category/:slug',
				element: <Category />,
			},
			{
				path: '/dashboard/categories/child/:slug',
				element: <ChildsCategory />,
			},
			{
				path: '/dashboard/categories/child/create/:id',
				element: <CreateChildPage />,
			},
			{
				path: '/dashboard/categories/child/update/:id',
				element: <UpdateChildPage />,
			},
			{
				path: '/dashboard/categories/create',
				element: <CreateCategoryPage />,
			},
			{
				path: '/dashboard/categories/update/:id',
				element: <UpdateCategoryPage />,
			},

			/**
			 * ### EXPLORER
			 */
			{
				path: '/dashboard/explorer',
				element: <ExplorerPage />,
			},
			{
				path: '/dashboard/orders',
				element: <ProtectedRoute children={<OrdersPage />} isPublic={false} />,
			},
			{
				path: '/dashboard/orders/:id',
				element: <ProtectedRoute children={<OrderPage />} isPublic={false} />,
			},

			/**
			 * ### PROMOTIONS
			 */

			{
				path: '/dashboard/promotions',
				element: <PromotionsPage />,
			},
			{
				path: '/dashboard/promotions/promo/:id',
				element: <PromotionPage />,
			},
			{
				path: `/dashboard/promotions/create`,
				element: (
					<ProtectedRoute children={<CreatePromoPage />} isPublic={false} />
				),
			},
			{
				path: `/dashboard/promotions/add-product/:id`,
				element: (
					<ProtectedRoute children={<AddProductPage />} isPublic={false} />
				),
			},
			{
				path: `/dashboard/promotions/update/:id`,
				element: (
					<ProtectedRoute children={<UpdatePromoPage />} isPublic={false} />
				),
			},
			/**
			 * ### USER
			 */

			{
				path: '/dashboard/users',
				element: <UsersPage />,
			},
			{
				path: '/dashboard/forbbidden',
				element: <ForbbiddenPage />,
			},
			{
				path: '/dashboard/*',
				element: <NotFound />,
			},
		],
	},
])
