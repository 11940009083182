import { FC } from 'react'
import { MdDelete } from 'react-icons/md'
import { Button, Drawer, IconButton, List, Stack, Text } from 'rsuite'
import { IProduct } from '../../../interfaces/product.interface'

interface SelectedProductsProps {
	open: boolean
	setOpen: (value: boolean) => void
	products: IProduct[]
	removedHandle: (id: number) => void
	addHandle: () => void
}
export const SelectedProducts: FC<SelectedProductsProps> = ({
	open,
	setOpen,
	products,
	removedHandle,
	addHandle,
}) => {
	return (
		<Drawer
			backdrop={'static'}
			size={'xs'}
			placement={'right'}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Drawer.Header>
				<Drawer.Title>Выбранные товары</Drawer.Title>
			</Drawer.Header>
			<Drawer.Body>
				<List>
					{products.map((product) => (
						<List.Item key={product.id}>
							<Stack justifyContent="space-between">
								<Text color="yellow">{product.title}</Text>
								<IconButton
									appearance="subtle"
									icon={<MdDelete />}
									onClick={() => removedHandle(product.id)}
								/>
							</Stack>
						</List.Item>
					))}
				</List>
				<Button onClick={addHandle}>Добавить товары</Button>
			</Drawer.Body>
		</Drawer>
	)
}
