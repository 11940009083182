import apiBase from '../../api/AxiosBase'
import { IUserUpdateDTO } from '../../api/data-transfer/user.dto'
import { IUser } from '../../interfaces/user.interface'

export const userSerivce = {
	/**
	 *
	 * @param id
	 * @returns
	 */
	async findUserById(id: number | undefined | null) {
		if (id) {
			const response = await apiBase<IUser>({
				url: `/user/${id}`,
				method: 'Get',
			})
			return response.data
		}
		return null
	},
	/**
	 *
	 * @returns ALL USERS
	 */
	async findAllUsers() {
		const response = await apiBase<IUser[]>({
			url: '/user',
			method: 'Get',
		})
		return response.data
	},

	/**
	 *
	 * @returns FIND USER PROFILE
	 */
	async getUserProfile() {
		const response = await apiBase<IUser>({
			url: '/user/profile',
			method: 'Get',
		})
		return response.data
	},

	/**
	 *
	 * @param userId
	 * @param data
	 * @returns
	 */
	async updateUser(userId: number, data: IUserUpdateDTO) {
		const response = await apiBase<{ message: string }>({
			url: `/user/${userId}`,
			method: 'Put',
			data,
		})
		return response.data
	},
	/**
	 *
	 * @param id
	 * @returns Delete USER
	 */
	async deleteUser(id: number) {
		const response = await apiBase<{ message: string }>({
			url: `/user/${id}`,
			method: 'Delete',
		})
		return response.data
	},
}
