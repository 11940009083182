import { useQuery } from '@tanstack/react-query'
import { CategoryService } from '../../services/category/Category.service'

export const useFetchCategoryById = (id: number) => {
	const response = useQuery({
		queryKey: ['fetchCategoryById'],
		queryFn: () => CategoryService.fetchById(Number(id)),
		enabled: !!id,
		cacheTime: 0,
	})

	return response
}
