import { useQuery } from '@tanstack/react-query'
import { ProductService } from '../../services/product/Product.service'

export const useFetchAllProducts = () => {
	const response = useQuery({
		queryKey: ['fetchAllProducts'],
		queryFn: () => ProductService.fetchAllProducts(),
	})

	return response
}
