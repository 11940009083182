import { IProduct } from '../interfaces/product.interface'
import { IUser } from '../interfaces/user.interface'

export function isUsedPosterOrImages(
	product: IProduct | null,
	user: IUser | null,
	path: string
) {
	let message

	if (product && product.poster.includes(path.replace(/\\/g, '/'))) {
		message = 'Используется в постере'
	}

	// if (product?.attributes) {
	// 	for (const attribute of product.attributes) {
	// 		for (const image of attribute.images) {
	// 			if (image.includes(path.replace(/\\/g, '/'))) {
	// 				message = 'Используется в изображениях'
	// 			}
	// 		}
	// 	}
	// }

	if (user) {
		if (user.avatar && user?.avatar.includes(path.replace(/\\/g, '/'))) {
			message = 'Используется в аватарах'
		}
	}

	return message
}
