import { useQuery } from '@tanstack/react-query'
import { CardsImageComponent } from '../../components/cards/cards-image/Cards-image'
import { LoaderComponent } from '../../components/loader/Loader'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { useStorereducers } from '../../hooks/useStoreReducers'
import { UploadService } from '../../services/upload/upload.service'
import style from './explorer.module.scss'

export const ExplorerPage = () => {
	const { products } = useStorereducers((state) => state.product)

	const { data: files, isFetching } = useQuery(['findAllFiles'], () =>
		UploadService.findAllImages()
	)

	return (
		<div className={style.explorer}>
			<span className="item-title">Библиотека файлов</span>
			{isFetching ? (
				<LoaderComponent />
			) : files && products ? (
				<CardsImageComponent
					products={products.map((product) => product)}
					paths={files.paths}
					totalSize={files.totalSize}
				/>
			) : (
				<EmptyComponent />
			)}
		</div>
	)
}
