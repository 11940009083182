import { FC, PropsWithChildren, useState } from 'react'
import { DialogContent, DialogContext } from '../context/Dialog-context'

const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
	const [dialogOpen, setDialogOpen] = useState(false)
	const [dialogContent, setDialogContent] = useState<DialogContent | null>(null)

	const openDialog = (content: DialogContent) => {
		setDialogContent(content)
		setDialogOpen(true)
	}

	const closeDialog = () => {
		setDialogOpen(false)
	}

	return (
		<DialogContext.Provider
			value={{
				closeDialog,
				openDialog,
				dialogContent,
				isOpen: dialogOpen,
			}}
		>
			{children}
		</DialogContext.Provider>
	)
}

export default DialogProvider
