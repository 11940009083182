import { FC } from 'react'

import { MdLogout } from 'react-icons/md'
import { Avatar, Button, List, Whisper } from 'rsuite'
import { useLogOut } from '../../hooks/user/useLogout'
import { IUser } from '../../interfaces/user.interface'
import { PopoverWithLoader } from '../Popover/Popover'

interface IUserProps {
	user?: IUser | undefined | null
}
const User: FC<IUserProps> = ({ user }) => {
	const logoutUser = useLogOut()

	return user ? (
		<>
			<Whisper
				trigger="click"
				placement={'bottomEnd'}
				speaker={
					<PopoverWithLoader>
						<List style={{ padding: '10px' }}>
							<List.Item>Привет, {user.name}</List.Item>
							<List.Item>
								<Button startIcon={<MdLogout />} onClick={logoutUser}>
									Выйти
								</Button>
							</List.Item>
						</List>
					</PopoverWithLoader>
				}
			>
				<Avatar
					size="sm"
					circle
					color="violet"
					bordered
					src={`${process.env.REACT_APP_API_STATIC}${user?.avatar}`}
				></Avatar>
			</Whisper>
		</>
	) : null
}

export default User
