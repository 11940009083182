import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Message, Table, Text, toaster } from 'rsuite'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { LoaderComponent } from '../../components/loader/Loader'
import { EnumStatusOrder } from '../../enum/Order.enum'
import { useWebSocket } from '../../hooks/ws/useWebsocket'
import { IOrder } from '../../interfaces/order.interface'
import { OrderService } from '../../services/orders/Order-service'
import { formatDateString } from '../../utils/formate-date'

const { Column, HeaderCell, Cell } = Table

export const OrdersPage = () => {
	const queryClient = useQueryClient()
	const socket = useWebSocket()
	const navigate = useNavigate()

	const { data: orders, isLoading } = useQuery({
		queryKey: ['findAllOrders'],
		queryFn: () => OrderService.findAllOrders(),
	})

	const playNotificationSound = () => {
		const audio = new Audio('/sounds/iphone_sound.mp3')
		audio.play()
	}

	useEffect(() => {
		if (socket) {
			socket.on('sendOrder', (body: { placeOrder: boolean }) => {
				if (!body.placeOrder) return
				toaster.push(
					<Message showIcon type="info">
						Есть новый заказ
					</Message>
				)
				playNotificationSound()

				queryClient.invalidateQueries({ queryKey: ['findAllOrders'] })
			})
		}
		// eslint-disable-next-line
	}, [socket])

	return isLoading ? (
		<LoaderComponent />
	) : orders ? (
		<div className="table">
			<Table
				className="table"
				data={orders}
				bordered
				cellBordered
				autoHeight
				affixHeader
				affixHorizontalScrollbar
				height={400}
			>
				<Column align="center" width={140}>
					<HeaderCell>Метод оплаты</HeaderCell>
					<Cell dataKey="payment_type">
						{(rowData: IOrder) => <Text>{rowData.payment_type}</Text>}
					</Cell>
				</Column>
				<Column align="center" width={100}>
					<HeaderCell>Статус</HeaderCell>
					<Cell dataKey="status">
						{(rowData: IOrder) => <Text color="yellow">{rowData.status}</Text>}
					</Cell>
				</Column>
				<Column align="center" width={140}>
					<HeaderCell>Сумма заказа</HeaderCell>
					<Cell dataKey="totalCache">
						{(rowData: IOrder) => (
							<Text color="cyan">{rowData.totalCache}KGS</Text>
						)}
					</Cell>
				</Column>

				<Column align="center" width={140}>
					<HeaderCell>Заказчик</HeaderCell>
					<Cell dataKey="user">
						{(rowData: IOrder) => <span>{rowData.user.name}</span>}
					</Cell>
				</Column>

				<Column align="center" width={100}>
					<HeaderCell>Товаров</HeaderCell>
					<Cell dataKey="items">
						{(rowData: IOrder) => <Text>{rowData.items.length}</Text>}
					</Cell>
				</Column>
				<Column align="center" width={200} resizable>
					<HeaderCell>Дата заказа</HeaderCell>
					<Cell dataKey="createdAt">
						{(rowData: IOrder) => (
							<Text color="blue">{formatDateString(rowData.createdAt)}</Text>
						)}
					</Cell>
				</Column>
				<Column width={250} align="center">
					<HeaderCell>Действия</HeaderCell>
					<Cell dataKey="status" style={{ padding: '6px 0' }}>
						{(rowData: IOrder) =>
							rowData.status.includes(EnumStatusOrder.WAITING) ? (
								<Button
									appearance="primary"
									size="xs"
									color="red"
									onClick={() => navigate(`/dashboard/orders/${rowData.id}`)}
								>
									Необходимо провести заказ!
								</Button>
							) : (
								<Button
									appearance="primary"
									size="xs"
									color="green"
									onClick={() => navigate(`/dashboard/orders/${rowData.id}`)}
								>
									Заказ проведен
								</Button>
							)
						}
					</Cell>
				</Column>
			</Table>
		</div>
	) : (
		<EmptyComponent />
	)
}
