import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoPricetag } from 'react-icons/io5'
import { MdCountertops, MdDiscount } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Col,
	Divider,
	Form,
	Grid,
	Input,
	InputGroup,
	InputNumber,
	Message,
	Row,
	toaster,
	Toggle,
} from 'rsuite'
import { FormButtons } from '../../../components/form/actions/FormButtons'
import { Field } from '../../../components/form/field/Field'
import { Heading } from '../../../components/heading/Heading'
import { LoaderComponent } from '../../../components/loader/Loader'
import { ProductService } from '../../../services/product/Product.service'
import { IUpdateVariantDTO } from '../../../services/product/dto'
import style from './variant.module.scss'

export const UpdateVariantPage = () => {
	const { id } = useParams<{ id: string }>()
	const [isHit, setIsHit] = useState(false)
	const [isNew, setIsNew] = useState(false)
	const navigate = useNavigate()

	const { data, isLoading } = useQuery({
		queryKey: ['fetchVariantById'],
		queryFn: () => ProductService.fetchVariantById(Number(id)),
	})

	const { mutateAsync } = useMutation({
		mutationKey: ['updateVariant'],
		mutationFn: (dto: IUpdateVariantDTO) =>
			ProductService.updateVariant(dto, Number(data?.id)),
	})

	const {
		handleSubmit,
		control,
		setValue,
		formState: { isValid },
	} = useForm<IUpdateVariantDTO>({
		mode: 'all',
	})

	const updateHandle = async (values: IUpdateVariantDTO) => {
		try {
			if (data?.productId) {
				await mutateAsync(
					{
						...values,
						price: values.price ? Number(values.price) : undefined,
						stock: values.stock ? Number(values.stock) : undefined,
						discount: values.discount ? Number(values.discount) : undefined,
						isHit,
						isNew,
					},
					{
						onSuccess(data, variables, context) {
							toaster.push(
								<Message showIcon type="success">
									{data?.message}
								</Message>
							)
							navigate(-1)
						},
					}
				)
			}
		} catch (error) {
			toaster.push(
				<Message showIcon type="error">
					{error}
				</Message>
			)
		}
	}

	useEffect(() => {
		if (data) {
			setValue('price', data.price)
			setValue('discount', data.discount)
			setValue('stock', data.stock)
			setValue('size', data.size)
			setIsHit(data.isHit)
			setIsNew(data.isNew)
		}
	}, [data])

	return isLoading ? (
		<LoaderComponent />
	) : data ? (
		<Grid fluid>
			<Row className={style.row}>
				<Col xs={16} className={style.col}>
					<Heading
						style={{ margin: '12px 0px' }}
						type="h5"
						children={`Обновить ${data.id} вариант`}
					/>
					<Divider />
					<Form
						layout="horizontal"
						// @ts-ignore
						onSubmit={handleSubmit(updateHandle)}
					>
						<Controller
							name="price"
							control={control}
							rules={{ required: 'Цена обязательно' }}
							defaultValue={data.price}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<IoPricetag />
										</InputGroup.Addon>
										<InputNumber
											value={field.value}
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											placeholder="Цена"
											min={80}
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						<Controller
							name="stock"
							control={control}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<MdCountertops />
										</InputGroup.Addon>
										<InputNumber
											value={field.value}
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											min={0}
											placeholder="Количество на складе"
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>
						<Controller
							name="size"
							control={control}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<MdCountertops />
										</InputGroup.Addon>
										<Field field={field} placeholder="Размеры" />
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						<Controller
							name="discount"
							control={control}
							render={({ field, fieldState }) => (
								<Form.Group>
									<InputGroup>
										<InputGroup.Addon>
											<MdDiscount />
										</InputGroup.Addon>
										<InputNumber
											value={field.value}
											ref={field.ref}
											name={field.name}
											onChange={field.onChange}
											min={0}
											max={20}
											placeholder="Скидка"
										/>
									</InputGroup>

									<Form.ErrorMessage
										show={!!fieldState.error}
										placement="bottomStart"
									>
										{fieldState.error?.message}
									</Form.ErrorMessage>
								</Form.Group>
							)}
						/>

						{data.size ? (
							<Controller
								name="size"
								control={control}
								render={({ field, fieldState }) => (
									<Form.Group>
										<InputGroup>
											<InputGroup.Addon>
												<MdDiscount />
											</InputGroup.Addon>
											<Input
												ref={field.ref}
												name={field.name}
												value={field.value}
												onChange={field.onChange}
												placeholder="Размер"
											/>
										</InputGroup>

										<Form.ErrorMessage
											show={!!fieldState.error}
											placement="bottomStart"
										>
											{fieldState.error?.message}
										</Form.ErrorMessage>
									</Form.Group>
								)}
							/>
						) : null}

						<Form.Group>
							<Toggle
								size={'sm'}
								color={'violet'}
								checked={isHit}
								onChange={setIsHit}
							>
								Хит
							</Toggle>
						</Form.Group>

						<Form.Group>
							<Toggle
								size={'sm'}
								color={'violet'}
								checked={isNew}
								onChange={setIsNew}
							>
								Новинка
							</Toggle>
						</Form.Group>

						<FormButtons
							disabled={!isValid}
							isLoading={isLoading}
							type="update"
						/>
					</Form>
				</Col>
			</Row>
		</Grid>
	) : null
}
