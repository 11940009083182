import { FC } from 'react'
import {
	Divider,
	Nav,
	Sidebar as SidebarCMP,
	Sidenav,
	Stack,
	Text,
} from 'rsuite'

import { useNavigate } from 'react-router-dom'
import packageJson from '../../../package.json'
import { ISidebarProps } from './Sidebar.props'
import { MENU_DATA } from './menu.data'

import style from './sidebar.module.scss'

const Sidebar: FC<ISidebarProps> = () => {
	const navigate = useNavigate()
	return (
		<SidebarCMP width={220} collapsible className={style.sidebar}>
			<Sidenav.Header className={style.head}>
				<img src="/logo.png" alt="logo" />
				<small>Admin dashboard</small>
				<Divider />
			</Sidenav.Header>
			<Sidenav appearance="subtle">
				<Sidenav.Body>
					<Nav className={style.nav}>
						{MENU_DATA.map(({ Icon, name, to }, index) => (
							<Nav.Item
								role="link"
								key={index}
								eventKey={`${index}`}
								className={style.navItem}
								onClick={() => navigate(to)}
							>
								<span>{Icon}</span>
								{name}
							</Nav.Item>
						))}
					</Nav>
				</Sidenav.Body>
			</Sidenav>

			<Stack className={style.bottom} justifyContent={'center'}>
				<Text size={'sm'} color="violet">
					Версия приложения: {packageJson.version}
				</Text>
			</Stack>
		</SidebarCMP>
	)
}

export default Sidebar
