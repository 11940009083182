import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IProduct } from '../../../interfaces/product.interface'

interface IProductSliceState {
	products: IProduct[] | null | undefined
	errors?: string | null | undefined
	isError?: boolean
	isSuccess?: boolean
	isFetching?: boolean
}
const initialState: IProductSliceState = {
	products: null,
	errors: null,
	isError: false,
	isFetching: false,
	isSuccess: false,
}
export const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		addProducts: (state, { payload }: PayloadAction<IProduct[]>) => {
			state.products = payload
			state.isSuccess = true
			state.isError = false
			state.isFetching = false
		},
		setFetchError: (state, { payload }: PayloadAction<string>) => {
			state.errors = payload
			state.isError = true
			state.isFetching = false
			state.isSuccess = false
			state.products = null
		},
		setFetching: (state) => {
			state.isFetching = true
		},
	},
	extraReducers: (build) => {},
})

export const productReducer = productSlice.reducer
export const productActions = productSlice.actions
