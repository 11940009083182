import React from 'react'
import { MdEdit } from 'react-icons/md'
import { IconButton, Table } from 'rsuite'
import { imgHostPath } from '../../helpers/imgHostPath'
import { IUser } from '../../interfaces/user.interface'
import { formatDateString } from '../../utils/formate-date'
const { Column, HeaderCell, Cell } = Table

interface IUsersTableProps {
	isLoading?: boolean
	users: IUser[]
}
const UsersTable: React.FC<IUsersTableProps> = ({ isLoading, users }) => {
	return (
		<Table loading={isLoading} height={300} data={users} id="table">
			<Column width={80} align="center">
				<HeaderCell>Аватар</HeaderCell>
				<Cell style={{ padding: 6 }} dataKey="avatar">
					{(rowData: IUser) => (
						<img
							src={imgHostPath(rowData.avatar || '')}
							width="30"
							height={30}
							alt={rowData.name}
						/>
					)}
				</Cell>
			</Column>

			<Column width={140}>
				<HeaderCell>Имя</HeaderCell>
				<Cell dataKey="name" />
			</Column>

			<Column width={200}>
				<HeaderCell>E-mail</HeaderCell>
				<Cell dataKey="email" />
			</Column>

			<Column width={200}>
				<HeaderCell>Телефон</HeaderCell>
				<Cell dataKey="phoneNumber" />
			</Column>

			<Column width={100}>
				<HeaderCell>Заказов</HeaderCell>
				<Cell dataKey="orders">
					{(rowData: IUser) => <span>{rowData.orders.length}</span>}
				</Cell>
			</Column>

			<Column width={220}>
				<HeaderCell>Статус</HeaderCell>
				<Cell dataKey="isOnline">
					{(rowData: IUser) => (
						<span>
							{rowData.isOnline ? (
								'В сети'
							) : (
								<span>{formatDateString(rowData.lastVisited)}</span>
							)}
						</span>
					)}
				</Cell>
			</Column>

			{/* <Column width={100}>
						<HeaderCell title="Подробнее">Подробнее</HeaderCell>
						<Cell dataKey="slug">
							{(rowData: IProduct) => (
								<Link to={`/dashboard/products/${rowData.slug}`}>
									<span>Просмотр</span>
								</Link>
							)}
						</Cell>
					</Column> */}

			<Column width={150} align="center">
				<HeaderCell>Действия</HeaderCell>
				<Cell dataKey="id" style={{ padding: '6px' }}>
					{(rowData: IUser) => (
						<IconButton
							appearance="primary"
							color="blue"
							icon={<MdEdit />}
						></IconButton>
					)}
				</Cell>
			</Column>
		</Table>
	)
}

export default UsersTable
