export enum EnumStatusOrder {
	WAITING = 'WAITING',
	PAYED = 'PAYED',
	CANCELED = 'CANCELED',
}

export enum EnumPaymentMethod {
	CACHE = 'CACHE',
	CARD = 'CARD',
	MBANK = 'MBANK',
}
