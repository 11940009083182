import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FC, PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persist, store } from '../store/store'
import DialogProvider from './DialogProvider'
import ModalProvider from './ModalProvider'
import { WebSocketProvider } from './Ws.Provider'

const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})
const AppProvider: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Provider store={store}>
			<QueryClientProvider client={client}>
				<WebSocketProvider>
					<PersistGate persistor={persist} loading={null}>
						<DialogProvider>
							<ModalProvider>{children}</ModalProvider>
						</DialogProvider>
					</PersistGate>
				</WebSocketProvider>
			</QueryClientProvider>
		</Provider>
	)
}

export default AppProvider
