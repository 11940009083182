import { useQuery } from '@tanstack/react-query'
import { ProductService } from '../../services/product/Product.service'

export const useFetchProductBySlug = (slug?: string) => {
	const response = useQuery({
		queryKey: ['fetchProductBySlug'],
		queryFn: () => ProductService.fetchBySlug(slug),
		enabled: !!slug,
	})

	return response
}
