import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Message, Panel, toaster } from 'rsuite'
import { FormButtons } from '../../components/form/actions/FormButtons'
import { Field } from '../../components/form/field/Field'
import { LoaderComponent } from '../../components/loader/Loader'
import { EmptyComponent } from '../../components/loader/empty/Empty'
import { useFetchCategoryById } from '../../hooks/categories/useFetchCategoryById'
import { useModal } from '../../hooks/useModal'
import { CategoryService } from '../../services/category/Category.service'
import { IUpdateChildDTO } from '../../services/category/dto'

export const UpdateChildPage = () => {
	const { id } = useParams<{ id: string }>()
	const queryClient = useQueryClient()
	const { closeHandler } = useModal()
	const navigate = useNavigate()

	const {
		handleSubmit,
		control,
		setValue,
		formState: { isValid },
	} = useForm<IUpdateChildDTO>({ mode: 'onChange' })

	const { data, isLoading: loadingOldData } = useFetchCategoryById(Number(id))

	const { mutateAsync, isLoading } = useMutation(
		['createMainCategory'],
		(dto: IUpdateChildDTO) => CategoryService.updateChild(dto)
	)

	const createHandler = async (data: IUpdateChildDTO) => {
		try {
			if (!id) {
				toaster.push(
					<Message type="error" showIcon>
						Произошла ошибка. Повторите попытку позже
					</Message>
				)
				return
			}
			await mutateAsync(
				{
					...data,
					id: +id,
				},
				{
					onSuccess(data, variables, context) {
						toaster.push(
							<Message type="success" showIcon>
								{data.message}
							</Message>
						)
						queryClient.invalidateQueries(['fetchBySlug'])
						closeHandler()
						navigate(-1)
					},
				}
			)
		} catch (error) {
			toaster.push(
				<Message type="error" showIcon>
					{error}
				</Message>
			)
		}
	}

	useEffect(() => {
		if (data) {
			setValue('name', data.name)
		}
	}, [data])
	return loadingOldData ? (
		<LoaderComponent />
	) : data ? (
		<Panel header="Обновить категорию" style={{ width: 500 }}>
			<Form
				fluid
				// @ts-ignore
				onSubmit={handleSubmit(createHandler)}
			>
				<Form.Group>
					<Form.ControlLabel>Название категории</Form.ControlLabel>
					<Controller<IUpdateChildDTO>
						name="name"
						control={control}
						render={({ field, fieldState, formState }) => (
							<Field
								field={field}
								error={fieldState.error?.message}
								style={{ height: '50px' }}
							/>
						)}
					/>
				</Form.Group>

				<FormButtons disabled={!isValid} isLoading={isLoading} type="submit" />
			</Form>
		</Panel>
	) : (
		<EmptyComponent />
	)
}
