import apiBase from '../../api/AxiosBase'
import { IOrder } from '../../interfaces/order.interface'
import { IChangeStatusOrderDTO, IChangeStatusResponse } from './dto'

const ORDER_PATH = 'order'
export const OrderService = {
	/**
	 *
	 * @returns ALL ORDERS
	 */
	async findAllOrders() {
		const response = await apiBase<IOrder[]>({
			url: ORDER_PATH,
			method: 'GET',
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @param dto
	 * @returns Changed status
	 */
	async changeStatus(dto: IChangeStatusOrderDTO) {
		const response = await apiBase<IChangeStatusResponse>({
			url: `/${ORDER_PATH}/${dto.id}`,
			method: 'POST',
			data: dto,
		})
		return response.data
	},

	/**
	 *
	 * @param id
	 * @returns ORDER
	 */
	async fetchById(id: number) {
		const response = await apiBase<IOrder>({
			url: `/${ORDER_PATH}/${id}`,
			method: 'GET',
		})
		return response.data
	},
}
