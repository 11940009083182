import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { userSerivce } from '../../services/user/user.service'
import { useStoreActions } from '../useStoreActions'
import { useUser } from './useUser'

export const useFetchUserProfile = () => {
	const { isAuth, user } = useUser()
	const { authUser } = useStoreActions()

	const { data } = useQuery(
		['fetchUserProfile'],
		() => userSerivce.getUserProfile(),
		{ enabled: isAuth, cacheTime: 0 }
	)

	useEffect(() => {
		if (isAuth) {
			if (data) {
				authUser(data)
			}
		}
		// eslint-disable-next-line
	}, [isAuth, data])

	return user
}
